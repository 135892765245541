import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {getEndPoint} from '../../util/api/enpoints'

import { sendUserInfo, updateUserInfo } from '../../redux/slices/userSlice'
import PageBodyContainer from '../CommonStyles/PageBodyContainer'
import styled from '@emotion/styled'
import InputsContainer from '../CommonStyles/InputsContainer'
import Form from '../CommonStyles/Form'
import Label from '../CommonStyles/Label'
import Button from '../CommonStyles/Button'
import useFormFields from '../../hooks/FormField'
import { getURL } from '../../constants'
import Text from '../CommonStyles/Text'
import { Link } from 'react-router-dom'
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import {fetchEventData, setForceLogout} from "../../redux/slices/eventSlice";
import store from "../../redux/store";

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`
const validatePassword = (password: string) => {
  const errors = []

  if (password.length < 6) {
    errors.push('Password should be at least 5 charcters long')
  }

  return errors
}

const Input = styled('input')`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

interface Params {
  eventcode: string
}

const LoginPage: React.FC = () => {
  // const eventState = useSelector((state: any) => state.event)
  const { eventcode } = useParams<Params>()
  const dispatch = useDispatch()
  const history = useHistory()
  const email = useSelector((state: any) => state.user.email)
  const event = useSelector((state: any) => state.event)
  const signalrState = useSelector((state: any) => state.signalr)
  const [errors, setErrors] = useState<string[]>([])

  // if no email punt to events page
  if (!email || email.length <= 0) {
    history.push('/' + eventcode)
  }

  const userAccountStatus = useSelector(
    (state: any) => state.user.accountstatus
  )
  const userAccountSetupComplete = useSelector(
    (state: any) => state.user.accountSetupComplete
  )

  const { formFields, createChangeHandler } = useFormFields({
    password: ''
  })

  const passwordInputRef = useRef<any>()

  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus()
    }
    store.dispatch(setForceLogout(false))
  })

  useEffect(() => {
    if (signalrState.connected) {
      if (userAccountStatus === 'notverified') {
        history.push('/' + eventcode + '/verify-email')
      } else {
        if (userAccountSetupComplete) {
          history.push('/' + eventcode + '/welcome/')
        } else {
          history.push('/' + eventcode + '/setup-account')
        }
      }
    }
  }, [signalrState, userAccountStatus, userAccountSetupComplete, history])

  const onSubmit = async (e: any) => {
    e.preventDefault()

    const password: string = formFields.password
    // const passwordErrors = validatePassword(password)

    // if (passwordErrors.length > 0) {
    //   setErrors(() => passwordErrors)
    // } else {
    //   setErrors([])
    // }

    // if (passwordErrors.length <= 0) {
    const url = getEndPoint('LOGIN_URL')
    const userInfo = {
      url,
      password,
      email,
      eventcode: event.eventcode,
      auctioncode: event.auctioncode
    }
    try {
      const response: any = await dispatch(sendUserInfo(userInfo))
      if (response.error) {
        throw new Error('Internal server Error')
      }
      else if (response.payload) {
        const responseJSON = JSON.parse(response.payload.jsonInfo)
        if (responseJSON.status === 'success') {
          setErrors([])
          const token = responseJSON.token
          const accountstatus = responseJSON.accountstatus
          const bidderdisplaynumber = responseJSON.bidderdisplaynumber
          const setup = responseJSON.setupcomplete
          const userid = responseJSON.userid
          if (localStorage.getItem('token')) {
            localStorage.removeItem('token')
          }
          localStorage.setItem('token', token)
          dispatch(
            updateUserInfo({
              accountstatus,
              accountSetupComplete: setup,
              userid,
              firstname: responseJSON.firstname,
              lastname: responseJSON.lastname,
              authorizebidder: responseJSON.authorizebidder,
              bidderdisplaynumber: responseJSON.bidderdisplaynumber,
              isLoggedIn: true,
              notifications: responseJSON.notifications,
              eventcode: userInfo.eventcode,
              auctioncode: userInfo.auctioncode
            })
          )
          // dispatch({
          //   type: "CONNECT_SIGNALR",
          //   payload: {
          //     url: getURL(),
          //     token
          //   }
          // })
          dispatch({
            type: 'TOKEN_CONNECT',
            payload: {
              url: getURL(),
              token: token
            }
          })
          console.log(localStorage.getItem('token'))
          console.log('finish')
        }
        else if (responseJSON.status === 'failure') {
          setErrors([responseJSON.message])
        }
      }
    } catch (err) {
      console.log(err)
    }
    // }
  }

  return (
    <>
      <PageBodyContainer>
        <EventNameContainer>
          {event.eventname && (
              <Text
                  fontSize="35px"
                  fontWeight="bold"
                  textAlign="center"
                  padding="24px 0 16px 0"
              >
                {event.eventname}
              </Text>
          )}

          <Text fontSize="20px" textAlign="center" padding="24px">
            <div
                dangerouslySetInnerHTML={{
                  __html: event.homemessage
                }}
            />
          </Text>
        </EventNameContainer>
        <WaveContainer></WaveContainer>
        <Form onSubmit={onSubmit}>
          <InputsContainer>
            <Label htmlFor="email">Email Address</Label>
            <Input
              type="text"
              id="email"
              autoFocus={true}
              placeholder="Enter your email here"
              name="email"
              value={email}
              disabled
            ></Input>
          </InputsContainer>
          <InputsContainer>
            <Label htmlFor="password">Password</Label>
            <Input
              ref={passwordInputRef}
              type="password"
              id="password"
              placeholder="Enter your password"
              name="password"
              value={formFields.password}
              onChange={createChangeHandler('password')}
            ></Input>
          </InputsContainer>
          {errors.length > 0 &&
          errors.map((err) => <div key={err}>Error : {err}</div>)}
          <Button style={{ zIndex: 1 }} type="submit" height="35px" width="200px">
            Sign In
          </Button>
          <LinkContainer>
            <Text style={{ zIndex: 1, paddingBottom: 25 }} important fontSize="15px">
              <Link style={{ zIndex: 1, textDecoration: 'none', marginBottom: 20 }} to={"/" + eventcode + "/reset-password"}>
                Forgot Password?
              </Link>
            </Text>
          </LinkContainer>
        </Form>
      </PageBodyContainer>

      <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
        <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
          AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
          <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
                  </span>
        </div>
      </div>
    </>
  )
}

export default LoginPage
