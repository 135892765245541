import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {
  IonBackdrop,
  IonButton,
  IonCardTitle,
  IonCol, IonContent, IonFooter,
  IonGrid, IonHeader, IonItem, IonLabel,
  IonModal, IonPage,
  IonRow,
  IonText, IonTitle,
  IonToast,
  IonToggle, IonToolbar, useIonModal
} from "@ionic/react";

import {
  checkTokenOnRefresh,
  updateUserInfo,
  updateUserProfile,
  useUserInfo,
} from "../../redux/slices/userSlice";
import { USER_PROFILE, getEndPoint } from "../../util/api/enpoints";
import ButtonItem from "../CommonStyles/ButtonItem";
import Text from "../CommonStyles/Text";
import {
  NotificationContainer,
  PageWrapper,
  RightContainer,
  RightItemContainer,
} from "./StyledComponents";
import styled from "@emotion/styled"
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import NumberFormat from "react-number-format";
import CheckBox from "../../components/CheckBox/Index"
import {Span} from "../AuctionItems/styledComponents";
import {OverlayEventDetail} from "@ionic/react/dist/types/components/react-component-lib/interfaces";

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const ModalContent = styled.div`
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  height: 32em;
  text-align: left;
  h1 {
    font-family: var(--ion-font-family);
    font-size: 24px/33px;
    font-weight: bold;
  }
  p {
    font-family: var(--ion-font-family);
  }
  letter-spacing: 0px;
  color: #333333;
`

interface Params {
  eventcode: string
}

const Input = styled("input")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`


const MessageModal = ({
                        onDismiss,
                        message,
                      }: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  message: string;
}) => {
  const inputRef = useRef<HTMLIonInputElement>(null);
  return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Text Messaging Opt-In</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem lines={'none'}>
            <IonLabel class={'ion-text-wrap'} style={{ fontSize: '18px'}}>You have provided your phone number but have not checked the box to opt-in to event related text messages.</IonLabel>
          </IonItem>
          <IonItem lines={'none'}>
            <IonLabel class={'ion-text-wrap'} style={{ fontSize: '18px'}}>Are you sure you sure you want to proceed?</IonLabel>
          </IonItem>
        </IonContent>
        <IonFooter>
          <IonRow style={{marginBottom: '20px'}}>
            <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
              <IonButton onClick={() => onDismiss(inputRef.current?.value, 'cancel')} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                Go Back
              </IonButton>
            </IonCol>
            <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
              <IonButton onClick={() => onDismiss(inputRef.current?.value, 'ok')} size="large" style={{ width: '90%' }} expand="block" fill="solid" color={'primary'}>
                Proceed without Text Messages
              </IonButton>
            </IonCol>
          </IonRow>
        </IonFooter>
      </IonPage>
  );
};

const AccountNotifications: React.FC = () => {
  const [present, dismiss] = useIonModal(MessageModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
    message: '',
  });

  function openTextModal() {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'ok') {
          onSave(true)
        }
      },
    });
  }

  const { eventcode } = useParams<Params>()
  const dispatch = useDispatch();
  const history = useHistory()
  const user = useUserInfo()
  const eventState = useSelector((state: any) => state.event)
  const [agreeToMessages, setagreeToMessages] = useState(false)
  const [cellPhone, setCellPhone] = useState('')
  const userStateNotifications = useSelector((state: any) => state.user.notifications)
  const [toastObj, setToast] = useState({ display: false, message: '', goBack: false });
  const [formState, setFormState] = useState<any>();

  useEffect(() => {
    const getUserData = async () => {
      await dispatch(
          checkTokenOnRefresh({ url: getEndPoint(USER_PROFILE) })
      )
    }
    getUserData()
  }, [dispatch])

  useEffect(() => {

    if (user && user.phoneNumber) {
      setCellPhone(user.phoneNumber.toString())
      setagreeToMessages(user.allowtexting)
    }
    if (eventState && eventState?.notificationcolumns && userStateNotifications && userStateNotifications.length > 0) {
      let notificationState = {}
      eventState?.notificationcolumns.forEach((itemColumn) => {
        userStateNotifications?.forEach((item: any) => {
          const keyValue = `toggle${itemColumn.value}${item.notificationrowid}`
          notificationState = {
            ...notificationState,
            [keyValue]: item[itemColumn.value]
          }
        })
      })

      setFormState(notificationState);
    }

  }, [userStateNotifications, eventState]);

  const onToggleChange = (type) => (e) => {
    e.preventDefault()
    if (e?.target?.checked === undefined) {
      console.log('skip')
    }
    else {
      const keys = formState ? Object.keys(formState) : []
      if (keys && keys.length > 0) {
        let copyState = {...formState}
        copyState[type] = e?.target?.checked
        // console.log(Object.keys(copyState))
        setFormState(copyState)
      }
    }
  }

  const goBack = () => {
    console.log('goBack')
    if (toastObj.goBack) {
      const mypath = '/' + eventcode + '/my-account'
      history.push(mypath)
    }
  }

  const handleCheckboxChange = (e: any) => {
    setagreeToMessages(e.target.checked)
  }

  const onSave = async (skipCheck) => {
    if (!skipCheck && cellPhone && cellPhone.length > 0 && !agreeToMessages) {
      openTextModal()
      return
    }
    const url = getEndPoint(USER_PROFILE);

    const notificationsData = userStateNotifications.map((item: any) => {
      let rowObject = { "notificationrowid": item.notificationrowid, "rownumber": item.notificationrowid  }
      eventState?.notificationcolumns.forEach((itemColumn) => {
        const keyValue = `toggle${itemColumn.value}${item.notificationrowid}`
        // console.log(keyValue)
        // console.log(formState[keyValue])
        rowObject[itemColumn.value] = formState[keyValue]
      })
      return rowObject
    })


    // console.log({ ...user, notifications: notificationsData, url })
    const newCell = cellPhone.replace(/\D/g,'')
    const response: any = await dispatch(updateUserProfile({ ...user, cellphone: newCell, phoneNumber: newCell, allowtexting: agreeToMessages, notifications: notificationsData, url }));
    if (response.error) {
      setToast({ display: true, message: 'There is an error saving your notifications.', goBack: false })
    } else if (response.payload) {
      const responseJSON = JSON.parse(response.payload.jsonString)
      console.log(responseJSON)

      dispatch(
          updateUserInfo({
            notifications: notificationsData
          })
      )

      if (responseJSON.status === "success") {
        setToast({ display: true, message: 'Successfully saved your notfications.', goBack: true })
      }
      else {
        setToast({ display: true, message: 'There is an error saving your notfications.', goBack: false })
      }
    }
  };

  return (
    <PageWrapper>
      <EventNameContainer>
        <Text
            fontSize="35px"
            fontWeight="bold"
            textAlign="center"
            padding="24px 0 16px 0"
        >
          Notifications
        </Text>
      </EventNameContainer>
      <WaveContainer></WaveContainer>
          <IonToast
            isOpen={toastObj.display}
            onDidDismiss={() => goBack()}
            message={toastObj.message}
            cssClass={'custom-toast'}
            duration={3000}
        />
      <IonGrid>
        <IonRow>
          <IonCol className="ion-align-items-end" size="6"></IonCol>
                {eventState?.notificationcolumns.map((itemColumn) => {
                    return (
                        <IonCol className="ion-align-items-end" size={'2'}>
                          <IonText class="ion-float-lg-right" style={{color: 'black', fontSize: 18}}>{itemColumn.title}</IonText>
                        </IonCol>
                    )})
                }
        </IonRow>
        <IonRow>
          {eventState?.notificationrows.map((itemRow) => {
            let rowItems = null

            rowItems = eventState?.notificationcolumns.map((itemColumn) => {
              if(itemRow[itemColumn.value]) {
                const checked = formState && formState[`toggle${itemColumn.value}${itemRow.notificationrowid}`]
                return (
                    <IonCol className="ion-align-items-end" size={'2'}>
                      <IonToggle class="ion-float-lg-right"
                                 name={`toggle${itemColumn.value}${itemRow.notificationrowid}`}
                                 checked={checked}
                                 onIonChange={onToggleChange(`toggle${itemColumn.value}${itemRow.notificationrowid}`)}
                      />
                    </IonCol>
                )
              }
              else {
                return (
                    <IonCol className="ion-align-items-end" size={'2'}>
                    </IonCol>
                )
              }
            })


            const headerRow = (
                <>
                  <IonCol className="ion-align-items-end" size="6">{itemRow.title}</IonCol>
                  {rowItems}
                </>
            )
            return headerRow
          })}
        </IonRow>
        <IonRow style={{borderBottom: '1px solid #D3D3D3', margin: "20px 0px 20px 0px"}}></IonRow>
        <IonRow>
          <IonCol className="ion-align-items-end" size="12">
            <NumberFormat
                style={{width: '100%'}}
                type="tel"
                id="cellphone"
                placeholder="Cell Phone Number"
                name="cellphone"
                value={cellPhone}
                onChange={(e) => { setCellPhone(e.target.value) }}
                format="(###) ###-####" mask="_"
                customInput={Input}/>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-align-items-start" size="6">
            <Text fontSize="20px" fontWeight={600} margin={"20px 0px 20px 0px"}>Text Messaging Opt-In</Text>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-align-items-start" size="12">
            {/*<IonItem class={'ion-align-items-end'} lines={'none'} >*/}
            <div className={'left-align-input'}>
              <label>
                <CheckBox
                    name="item"
                    checked={agreeToMessages}
                    handleCheckboxChange={handleCheckboxChange}
                    // checked={props.formFields.allowtexting}
                    // handleCheckboxChange={props.createChangeHandler("allowtexting")}
                    param="item"
                    checkedBackgroundColor="#5732db"
                    uncheckedBackgroundColor="white"
                    height="24px"
                    width="24px"
                    borderRadius="5px"
                >
                </CheckBox>
              </label>
              <Span>I agree to receive text messages through the AuctionSnap platform for notifications and event messages</Span>
            </div>
            {/*</IonItem>*/}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="ion-align-items-start" size="12">
            <Span>At any time, text STOP to unsubscribe from all text messages through AuctionSnap. Standard messaging rates may apply. You can adjust your notification preferences above and within AuctionSnap under your account profile. </Span>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonRow className="ion-align-items-center" style={{ marginTop: 16 }}>
        <IonCol>
          <ButtonItem height="45px" onClick={() => { onSave(false) }}>
            Save
          </ButtonItem>
        </IonCol>
      </IonRow>
    </PageWrapper>
  );
};

export default AccountNotifications;
