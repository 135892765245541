import React from "react"
import { useSelector } from "react-redux"
import {Route, Switch, useParams} from "react-router"

import Text from "../../pages/CommonStyles/Text"
import AccountDetailsHeader from "./AccountDetailsHeader"
import AccountProfileHeader from "./AccountProfileHeader"
import AuctionItemsHeader from "./AuctionItemHeader"
import EventWelcome from "./EventWelcome"
import Img from "./Img"
import ItemDetailsHeader from "./ItemDetailsHeader"
import LoginWelcome from "./LoginWelcome"
import LogoContainer from "./LogoContainer"
import LogoWrapper from "./LogoWrapper"
import PlaceBidHeader from "./PlaceBidHeader"
import SecondaryHeader from "./SecondaryHeader"
import Wrapper from "./Wrapper"
import logo from "./AuctionSnap.png"
import NotFound from "../../pages/NotFound/NotFound";

const Header: React.FC = (props) => {
  const eventResponse = useSelector((state: any) => state.event.eventData)
  const itemid = useSelector((state: any) => state.auctionItemDetails.itemid);
  const eventFetchState = useSelector(
    (state: any) => state.event.status === "loading"
  )

  const user = useSelector((state: any) => state.user)

  return (
    <Wrapper>
      {/* <Menu></Menu> */}
      <Switch>

        {/*<Route exact={true} path="/" component={NotFound}>*/}
        {/*  <LoginWelcome*/}
        {/*      eventResponse={eventResponse}*/}
        {/*      isEventLoading={eventFetchState}*/}
        {/*  ></LoginWelcome>*/}
        {/*</Route>*/}
        <Route path="/:eventcode/slideshow/">
          {/*<LoginWelcome*/}
          {/*  eventResponse={eventResponse}*/}
          {/*  isEventLoading={eventFetchState}*/}
          {/*></LoginWelcome>*/}
        </Route>
        <Route exact={true} path="/:eventcode">
          <LoginWelcome
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
          ></LoginWelcome>
        </Route>
        <Route exact={true} path="/:eventcode/auth/resetPassword/:id">
          <LoginWelcome
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
          ></LoginWelcome>
        </Route>
        <Route path="/:eventcode/verify-email">
          <LoginWelcome
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
          ></LoginWelcome>
        </Route>
        <Route path="/:eventcode/verify">
          <LoginWelcome
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
          ></LoginWelcome>
        </Route>
        <Route path="/:eventcode/login">
          <LoginWelcome
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
          ></LoginWelcome>
        </Route>
        <Route path="/:eventcode/create-account">
          <LoginWelcome
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
          ></LoginWelcome>
        </Route>
        <Route path="/:eventcode/setup-account">
          <LoginWelcome
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
          ></LoginWelcome>
        </Route>
        <Route path="/:eventcode/reset-password">
          <LoginWelcome
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
          ></LoginWelcome>
        </Route>
        <Route path="/:eventcode/reset-password-email">
          <EventWelcome
            eventResponse={eventResponse}
            isEventLoading={eventFetchState}
            showWelcomeMsg={true}
            hideHamburgerMenu={false}
          ></EventWelcome>
        </Route>
        <Route path="/:eventcode/welcome/">
          <AuctionItemsHeader
              eventResponse={eventResponse}
          ></AuctionItemsHeader>
        </Route>
        <Route exact path="/:eventcode/auction-items">
          <AuctionItemsHeader
            eventResponse={eventResponse}
          ></AuctionItemsHeader>
        </Route>
        <Route exact path="/:eventcode/auction-items/:id">
          <AuctionItemsHeader eventResponse={eventResponse} includeBack={true} backPath={'/' + eventResponse.eventcode + '/auction-items'} itemid={itemid}></AuctionItemsHeader>
        </Route>
        <Route exact path="/:eventcode/reset-password-account">
          {/* <Menu></Menu> */}
          <AccountProfileHeader
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
              showWelcomeMsg={true}
              userInfo={user}
              title="Reset Password"
              path="/:eventcode/my-account"
          >
          </AccountProfileHeader>
        </Route>
        <Route exact path="/:eventcode/account-profile">
          <AccountProfileHeader
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
              showWelcomeMsg={false}
              userInfo={user}
              title="My Profile"
              path={"/" + eventResponse.eventcode + "/my-account"}
          >
          </AccountProfileHeader>
        </Route>
        <Route exact path="/:eventcode/contact-us">
          {/* <Menu></Menu> */}
          <AccountProfileHeader
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
              showWelcomeMsg={false}
              userInfo={user}
              title="Contact Us"
              path={"/" + eventResponse.eventcode + "/my-account"}
          >
          </AccountProfileHeader>
        </Route>
        <Route exact path="/:eventcode/account-notifications">
          {/* <Menu></Menu> */}
          <AccountProfileHeader
              eventResponse={eventResponse}
              isEventLoading={eventFetchState}
              showWelcomeMsg={false}
              userInfo={user}
              title="Notifications"
          >
          </AccountProfileHeader>
        </Route>
        <Route path="/:eventcode/place-bid/:id">
          <AuctionItemsHeader eventResponse={eventResponse} includeBack={true} backPath={'/' + eventResponse.eventcode + '/auction-items/' + itemid} itemid={itemid}></AuctionItemsHeader>
        </Route>
        <Route path="/:eventcode/auto-bid/:id">
          <AuctionItemsHeader eventResponse={eventResponse} includeBack={true} backPath={'/' + eventResponse.eventcode + '/auction-items/' + itemid} itemid={itemid}></AuctionItemsHeader>
        </Route>
        <Route path="/:eventcode/not-found">
          <LogoWrapper>
            <LogoContainer>
              <Img alt="auction-snap" src={logo} margin="5px" />
            </LogoContainer>
          </LogoWrapper>
        </Route>
        <Route path="/:eventcode/my-orders">
          <AuctionItemsHeader
              eventResponse={eventResponse}
          ></AuctionItemsHeader>
        </Route>
        <Route path="/:eventcode/my-account">
          <AuctionItemsHeader
              eventResponse={eventResponse}
          ></AuctionItemsHeader>
        </Route>
        <Route path="/:eventcode/cart">
          {/* <Menu></Menu> */}
          <AuctionItemsHeader
              eventResponse={eventResponse}
          ></AuctionItemsHeader>
        </Route>
        {/*<Route path="/event/open-bids">*/}
        {/*  /!* <Menu></Menu> *!/*/}
        {/*  <SecondaryHeader*/}
        {/*    eventResponse={eventResponse}*/}
        {/*    isEventLoading={eventFetchState}*/}
        {/*    showWelcomeMsg={false}*/}
        {/*    userInfo={user}*/}
        {/*  ></SecondaryHeader>*/}
        {/*</Route>*/}
        <Route path="/:eventcode/checkout">
          <AuctionItemsHeader
              eventResponse={eventResponse}
          ></AuctionItemsHeader>
        </Route>
        <Route path="/:eventcode/checkout-item/:historyid">
          <AuctionItemsHeader
              eventResponse={eventResponse}
          ></AuctionItemsHeader>
        </Route>
        <Route exact={true} path="/" component={NotFound}>
          <LogoWrapper>
            <LogoContainer>
              <Img alt="auction-snap" src={logo} margin="5px" />
            </LogoContainer>
          </LogoWrapper>
        </Route>
      </Switch>
    </Wrapper>
  )
}

export default Header
