import React, {useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import {useHistory, useParams} from "react-router"

import styled from "@emotion/styled"

import useFormFields from "../../hooks/FormField"
import useIonicToggle from "../../hooks/IonicToggle"
import {checkTokenOnRefresh, updateUserInfo, updateUserProfile, useUserInfo} from "../../redux/slices/userSlice"
import { USER_PROFILE, getEndPoint } from "../../util/api/enpoints"
import Form from "../CommonStyles/Form"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text"
import StepOne from "./StepOne"
import StepThree from "./StepThree"
import StepTwo from "./StepTwo"
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import {fetchEventData, setAuctionCode, setEventCode} from "../../redux/slices/eventSlice";
import {setPrivacyEventCode} from "../../redux/slices/privacyPolicySlice";

const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

var totalSteps = 3
const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 7px;
`

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`

const CurrentStep = styled.div`
  margin-left: auto;
  margin-right: 10px;
`

interface Params {
  eventcode: string
}

const SetupAccount: React.FC = () => {
  const { eventcode } = useParams<Params>()
  const domainName =  window.location.hostname
  const domainNames = domainName.split('.')
  const auctioncode = (domainNames && domainNames.length > 0) ? domainNames[0] : 'app'
  const event = useSelector((state: any) => state.event)
  const dispatch = useDispatch()
  const history = useHistory()
  const userData = useSelector((state: any) => state.user)
  const eventState = useSelector((state: any) => state.event)
  const user = useUserInfo()
  const { notifications } = user
  const userid = userData.userid
  const [formNotificationState, setNotificationFormState] = useState<any>();
  const [currentStepInView, setCurrentStepInView] = useState(1)
  const { formFields, createChangeHandler } = useFormFields({
    firstname: "",
    lastname: "",
    cellphone: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zip: "",
    allowtexting: false,
    bidanonymously: userData.bidanonymously
  })

  if (eventState.notifications.shownotificationsmenuitem) {
    totalSteps = 3;
  }
  else {
    totalSteps = 2;
  }

  useEffect(() => {
    const url = getEndPoint('EVENT_ENDPOINT')
    const eventData = {
      url,
      eventcode: eventcode,
      auctioncode: auctioncode
    }
    dispatch(fetchEventData(eventData))
    dispatch(setEventCode(eventcode))
    dispatch(setAuctionCode(auctioncode))
    dispatch(setPrivacyEventCode(eventcode))
  }, [eventcode, auctioncode])

  const handleSubmit = (e: any) => {
    if (eventState.notifications.shownotificationsmenuitem) {
      goToNextView(e)
    }
    else {
      onSubmit(e)
    }
  }

  const goToNextView = (e: any) => {
    if (e) {
      e.preventDefault()
    }

    setCurrentStepInView(currentStepInView + 1)
  }

  const goToPreviousView = (e: any) => {
    e.preventDefault()
    setCurrentStepInView(currentStepInView - 1)
  }

  const onFinal = async (e: any) => {
    e.preventDefault()
    // const address = formFields.addressOne + formFields.addressTwo

    const notificationsData = notifications.map((item: any) => {
      let rowObject = { "notificationrowid": item.notificationrowid }
      eventState?.notificationcolumns.forEach((itemColumn) => {
        const keyValue = `toggle${itemColumn.value}${item.notificationrowid}`
        rowObject[itemColumn.value] = formNotificationState[keyValue]
      })
      return rowObject
    })

    const profileInfo = {
      ...formFields,
      address: formFields.addressOne,
      address2: formFields.addressTwo,      
      cellphone: formFields.cellphone.replace(/\D/g,''),
      notifications: notificationsData,
      bidanonymously: formFields.bidanonymously === 'on' ? true : false,
      userid,
      eventcode
    }
    delete profileInfo.addressOne
    delete profileInfo.addressTwo
    const url = getEndPoint(USER_PROFILE)
    const userProfileInfo = {
      url,
      ...profileInfo
    }
    console.log(userProfileInfo)
    try {
      const response: any = await dispatch(updateUserProfile(userProfileInfo))
      if (response.error) {
        throw new Error("Internal server Error")
      } else if (response.payload) {
        const responseJSON = JSON.parse(response.payload.jsonString)
        if (responseJSON.status === "success") {
          history.push("/" + eventcode + "/welcome/")
        }

      }
    } catch (err) {
      console.log(err)
    }


    const response: any = await dispatch(
        checkTokenOnRefresh({ url: getEndPoint(USER_PROFILE) })
    )
    console.log(response)
    if (response.error) {
      console.log(response.error)
    } else if (response.payload) {
      dispatch(
          updateUserInfo({
            ...response.payload,
            isLoggedIn: true
          })
      )
    }
  }

  const onSubmit = async (e: any) => {
    if (e) {
      e.preventDefault()
    }

    // const address = formFields.addressOne + formFields.addressTwo

    const notificationsData = notifications?.map((item: any) => {
      let rowObject = { "notificationrowid": item.notificationrowid }
      eventState?.notificationcolumns.forEach((itemColumn) => {
        const keyValue = `toggle${itemColumn.value}${item.notificationrowid}`
        rowObject[itemColumn.value] = formNotificationState[keyValue]
      })
      return rowObject
    })

    const profileInfo = {
      ...formFields,
      address: formFields.addressOne,
      address2: formFields.addressTwo,
      cellphone: formFields.cellphone.replace(/\D/g,''),
      allowtexting: formFields.allowtexting,
      notifications: notificationsData,
      bidanonymously: formFields.bidanonymously === 'on' ? true : false,
      userid,
      eventcode
    }
    delete profileInfo.addressOne
    delete profileInfo.addressTwo
    const url = getEndPoint(USER_PROFILE)
    const userProfileInfo = {
      url,
      ...profileInfo
    }
    console.log(userProfileInfo)
    try {
      const response: any = await dispatch(updateUserProfile(userProfileInfo))
      if (response.error) {
        throw new Error("Internal server Error")
      } else if (response.payload) {
        const responseJSON = JSON.parse(response.payload.jsonString)
        if (responseJSON.status === "success") {
          goToNextView(e)
        }

      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <PageBodyContainer alignItems="flex-start">
      <EventNameContainer>
        {event.eventname && (
            <Text
                fontSize="35px"
                fontWeight="bold"
                textAlign="center"
                padding="24px 0 16px 0"
            >
              {event.eventname}
            </Text>
        )}

        {/*<Text fontSize="20px" textAlign="center" padding="24px">*/}
        {/*    <div*/}
        {/*        dangerouslySetInnerHTML={{*/}
        {/*            __html: event.homemessage*/}
        {/*        }}*/}
        {/*    />*/}
        {/*</Text>*/}
      </EventNameContainer>
      <WaveContainer></WaveContainer>
      <InfoContainer>
        <Text important fontWeight="600" margin={"15px 0px 0px 0px"}>
          Set Up Your Account
        </Text>
        <CurrentStep>
          <Text important fontSize="15px">
            Step {currentStepInView} of {totalSteps}
          </Text>
        </CurrentStep>
      </InfoContainer>
      <Form onSubmit={onSubmit}>
        {currentStepInView === 1 && (
          <StepOne
            formFields={formFields}
            createChangeHandler={createChangeHandler}
            goToNextView={goToNextView}
          ></StepOne>
        )}
        {currentStepInView === 2 && (
          <StepTwo
            formFields={formFields}
            formNotificationState={formNotificationState}
            setNotificationFormState={setNotificationFormState}
            createChangeHandler={createChangeHandler}
            goToNextView={goToNextView}
            goToPreviousView={goToPreviousView}
            handleSubmit={onSubmit}
          ></StepTwo>
        )}
        {currentStepInView === 3 && (
          <StepThree
            formFields={formFields}
            createChangeHandler={createChangeHandler}
            goToPreviousView={goToPreviousView}
            handleFinish={onFinal}
          ></StepThree>
        )}
      </Form>
    </PageBodyContainer>
  )
}

export default SetupAccount
