import React, {useEffect, useState} from "react"
import NumberFormat from "react-number-format"
import { useDispatch, useSelector } from "react-redux"
import {useHistory, useParams} from "react-router"

import styled from "@emotion/styled"
import {IonToast} from "@ionic/react";

import { usStates } from "../../constants"
import {checkTokenOnRefresh, updateUserProfile} from "../../redux/slices/userSlice"
import {USER_PROFILE, getEndPoint} from "../../util/api/enpoints"
import Button from "../CommonStyles/Button"
import Form from "../CommonStyles/Form"
import InputsContainer from "../CommonStyles/InputsContainer"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text"
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import {useAuthRefresh} from "../../hooks/authenicationHook";

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const Input = styled("input")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

const Select = styled("select")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

const Option = styled("option")`
  box-sizing: border-box;
  background-color: #0000000d;
  outline: none;
  border: none;
  ::placeholder {
    font-size: 14px;
    padding-left: 4px;
  }
`

const PostalCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-top: 10px;
`

const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  margin-top: 10px;
`

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

interface Params {
  eventcode: string
}

const AccountProfile: React.FC = () => {
  const { eventcode } = useParams<Params>()
  useAuthRefresh();

  const dispatch = useDispatch()
  const history = useHistory()
  const userData = useSelector((state: any) => state.user)
  const [toastObj, setToast] = useState({ display: false, message: '', goBack: false });

  useEffect(() => {
    const getUserData = async () => {
      await dispatch(
          checkTokenOnRefresh({ url: getEndPoint(USER_PROFILE) })
      )
    }
    getUserData()
  }, [dispatch])

  const goBack = () => {
    if (toastObj.goBack) {
      const mypath = '/' + eventcode + '/my-account'
      history.push(mypath)
    }
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()    
    if (e.target.firstname.value.length === 0) {
      return setToast({ display: true, message: 'Firstname is invalid.', goBack: false })
    }
    else if (e.target.lastname.value.length === 0) {
      return setToast({ display: true, message: 'Lastname is invalid.', goBack: false })
    }
    else if (e.target.cellphone.value) {
      e.target.cellphone.value = e.target.cellphone.value?.replace(/\D/g,'')
      if (e.target.cellphone.value.length !== 10) {
        return setToast({ display: true, message: 'Phone number is invalid.', goBack: false })
      }
    }
    // else if (e.target.address1.value.length === 0) {
      //   setToast({ display: true, message: 'Address 1 is invalid.', goBack: false })
      // }
      // else if (e.target.city.value.length === 0) {
        //   setToast({ display: true, message: 'City is invalid.', goBack: false })
        // }
        // else if (e.target.state.value.length === 0) {
          //   setToast({ display: true, message: 'State is invalid.', goBack: false })
          // }
    else if (e.target.zip.value.length === 0) {
      return setToast({ display: true, message: 'zip is invalid.', goBack: false })
    }

    const profileInfo = {
      firstname: e.target.firstname.value,
      lastname: e.target.lastname.value,
      address: e.target.address1.value,
      address2: e.target.address2.value,      
      city: e.target.city.value,
      state: e.target.state.value,
      zip: e.target.zip.value,
      cellphone: e.target.cellphone.value
    }
    const url = getEndPoint(USER_PROFILE)
    const userProfileInfo = {
      url,
      ...profileInfo
    }
    try {
      const response: any = await dispatch(updateUserProfile(userProfileInfo))
      if (response.error) {
        setToast({ display: true, message: 'There is an error saving your profile.', goBack: false })
      } else if (response.payload) {
        const responseJSON = JSON.parse(response.payload.jsonString)
        if (responseJSON.status === "success") {
          setToast({ display: true, message: 'Successfully saved your profile.', goBack: true })
        }
        else {
          setToast({ display: true, message: 'There is an error saving your profile.', goBack: false })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
      <PageBodyContainer>
        <EventNameContainer>
          <Text
              fontSize="35px"
              fontWeight="bold"
              textAlign="center"
              padding="24px 0 16px 0"
          >
            My Profile
          </Text>
        </EventNameContainer>
        <WaveContainer></WaveContainer>
        <IonToast
            isOpen={toastObj.display}
            onDidDismiss={() => goBack()}
            message={toastObj.message}
            duration={3000}
            cssClass={'custom-toast'}
        />
        <Form onSubmit={onSubmit}>

          <InputsContainer>
            <Text padding="1px" fontSize="14px" fontWeight="bold" color="#666666">Profile Email
            <Text padding="11px" fontSize="20px" fontWeight="bold" color="#666666">{userData.email}</Text></Text>
            <Text padding="8px" fontSize="14px" fontWeight="bold" color="#666666">*First Name</Text>
            <Input
                type="text"
                id="firstname"
                placeholder="Enter First Name"
                name="firstname"
                defaultValue={userData.firstname}
            ></Input>
            <Text padding="8px" fontSize="14px" fontWeight="bold" color="#666666">*Last Name</Text>
            <Input
                type="text"
                id="lastname"
                placeholder="Enter Last Name"
                name="lastname"
                defaultValue={userData.lastname}
            ></Input>
            <Text padding="8px" fontSize="14px" fontWeight="bold" color="#666666">Cell Phone</Text>
            <NumberFormat type="tel"
                id="cellphone"
                placeholder="Enter Cell Phone"
                name="cellphone"
                isNumericString
                defaultValue={userData.phoneNumber}
                value={userData.phoneNumber}
                format="(###) ###-####" mask="_" 
                customInput={Input}/>
            
            <Text padding="8px" fontSize="14px" fontWeight="bold" color="#666666">Address Line 1</Text>
            <Input
                type="text"
                id="address1"
                placeholder="Enter your Address"
                name="address1"
                defaultValue={userData.addressInfo.lineOne}
            ></Input>
            <Text padding="8px" fontSize="14px" fontWeight="bold" color="#666666">Address Line 2</Text>
            <Input
                type="text"
                id="address2"
                placeholder="Enter your Address 2"
                name="address2"
                defaultValue={userData.addressInfo.lineTwo}
            ></Input>
            <Text padding="8px" fontSize="14px" fontWeight="bold" color="#666666">City</Text>
            <Input
                type="text"
                id="city"
                placeholder="Enter your City"
                name="city"
                defaultValue={userData.addressInfo.city}
            ></Input>


            <FlexRow>
              <StateContainer>
                <Text padding="8px" fontSize="14px" fontWeight="bold" color="#666666">State</Text>
                <Select
                    name="state"
                    id="id"
                    defaultValue={userData.addressInfo.state}
                >
                  {usStates.map((state, index) => {
                    return (
                        <Option value={state.abbreviation} key={index} selected={state.abbreviation === userData.addressInfo.state ? true : null}>
                          {state.abbreviation}
                        </Option>
                    )
                  })}
                </Select>
              </StateContainer>
              <PostalCodeContainer>
                <Text padding="8px" fontSize="14px" fontWeight="bold" color="#666666">*Postal Code</Text>
                <Input
                    type="number"
                    id="zip"
                    placeholder="Enter your Postal Code"
                    name="zip"
                    defaultValue={userData.addressInfo.postalCode}
                ></Input>
              </PostalCodeContainer>
            </FlexRow>

            <Button style={{ zIndex: 1 }} type="submit" height="35px" width="280px">
              Submit
            </Button>
            <Text padding="8px" fontSize="14px" fontWeight="bold" color="#333333" textAlign="center">*Required Fields</Text>
          </InputsContainer>
        </Form>
      </PageBodyContainer>
  )
}

export default AccountProfile
