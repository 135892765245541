import {useHistory, useParams} from "react-router";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchEventData,
    setAuctionCode,
    setCartReset,
    setEventCode,
    setEventReset,
    setForceLogout
} from "../redux/slices/eventSlice";
import {AUCTION_ITEM_DETAIL_ENDPOINT, AUCTION_ITEMS_ENDPOINT, CART_ENDPOINT, getEndPoint} from "../util/api/enpoints";
import useScript from "./useScript";
import {fetchAuctionItemsData} from "../redux/slices/auctionItemsSlice";
import {fetchAuctionItemDetailsData} from "../redux/slices/auctionItemDetailsSlice";
import store from "../redux/store";
import {fetchCartData} from "../redux/slices/cartSlice";
import {updateStateOnLogout} from "../redux/slices/userSlice";

const useResetEvent = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const eventData = useSelector((state: any) => state.event)
    const itemid = useSelector((state: any) => state.auctionItemDetails?.itemid)
    const url = getEndPoint('EVENT_ENDPOINT')

    useEffect(() => {
        if (eventData.forcelogout) {
            store.dispatch(setForceLogout(false))
            dispatch(updateStateOnLogout())
            localStorage.removeItem("token")
            dispatch({
                type: "DISCONNECT_SIGNALR"
            })
        }
    }, [eventData.forcelogout])

    useEffect(() => {
        if (eventData.cartreset) {
            console.log('calling cart refresh')
            setCartReset(false)
            store.dispatch(
                fetchCartData(getEndPoint(CART_ENDPOINT))
            )
        }

        if (eventData.reset) {
            store.dispatch(setEventReset(false))
            const eventDataBody = {
                url,
                eventcode: eventData.eventcode,
                auctioncode: eventData.auctioncode
            }
            dispatch(fetchEventData(eventDataBody))
            dispatch(setEventCode(eventData.eventcode))
            dispatch(setAuctionCode(eventData.auctioncode))
            dispatch(
                fetchAuctionItemsData(getEndPoint(AUCTION_ITEMS_ENDPOINT))
            )

            if (itemid) {
                dispatch(
                    fetchAuctionItemDetailsData({
                        url: getEndPoint(AUCTION_ITEM_DETAIL_ENDPOINT),
                        itemId: itemid,
                    })
                )
            }
        }
    }, [eventData.reset, eventData.cartreset])

    return
}

export default useResetEvent