import styled from "@emotion/styled"
import { Console } from "console"
import React, {useEffect} from "react"
import { useDispatch, useSelector } from "react-redux"
import {useHistory, useParams} from "react-router"
import { isConstructorDeclaration } from "typescript"
import { forgotPassword } from "../../redux/slices/userSlice"
import {getEndPoint, FORGOT_PASSWORD, PASSWORD_RESET, RESET_PASSWORD} from "../../util/api/enpoints"
import Button from "../CommonStyles/Button"
import Form from "../CommonStyles/Form"
import InputsContainer from "../CommonStyles/InputsContainer"
import Label from "../CommonStyles/Label"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text"
import { Link } from "react-router-dom"
import {fetchEventData} from "../../redux/slices/eventSlice";
import jwt_decode from "jwt-decode";
import LogoWrapper from "../../components/Header/LogoWrapper";
import LogoContainer from "../../components/Header/LogoContainer";
import Img from "../../components/Header/Img";
import waveBluePng from "../../assets/img/svg/wave-blue.png";

const Input = styled("input")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

interface Params {
  eventcode: string
}

const ResetPassword: React.FC = () => {
  const { eventcode } = useParams<Params>()
  const event = useSelector((state: any) => state.event)
  const dispatch = useDispatch()
  const history = useHistory()
  const email = useSelector((state: any) => state.user.email)
  const stateeventcode = useSelector((state: any) => state.event.eventcode)

  const elementScroll = document.getElementById("scrollBody")
  if (elementScroll) {
    elementScroll.scroll(0,0)
  }

  useEffect(() => {
    if (stateeventcode === null) {
      const url = getEndPoint('EVENT_ENDPOINT')
      const domainName =  window.location.hostname
      const domainNames = domainName.split('.')
      const auctioncode = (domainNames && domainNames.length > 0) ? domainNames[0] : ''
      const eventData = {
        url,
        eventcode: eventcode,
        auctioncode: auctioncode
      }
      dispatch(fetchEventData(eventData))
    }
  })




  const onSubmit = async (e: any) => {
    e.preventDefault()
    const url = getEndPoint(PASSWORD_RESET)

    try {
      const response: any = await dispatch(
        forgotPassword({
          url,
          email,
          eventcode: eventcode,
          auctioncode: getAuctionCode()
        })
      )
      if (response.error) {
        throw new Error("Some Server Error !")
      } else if (response.payload) {
        const responseJSON = JSON.parse(response.payload.jsonString)
        if (responseJSON.status === "success") {
          history.push("/" + eventcode + "/reset-password-email")
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getAuctionCode = () => {
    const domainName =  window.location.hostname
    const domainNames = domainName.split('.')
    return (domainNames && domainNames.length > 0) ? domainNames[0] : 'app'
  }

  return (
    <PageBodyContainer>
      <EventNameContainer>
        {event.eventname && (
            <Text
                fontSize="35px"
                fontWeight="bold"
                textAlign="center"
                padding="24px 0 16px 0"
            >
              {event.eventname}
            </Text>
        )}

        {/*<Text fontSize="20px" textAlign="center" padding="24px">*/}
        {/*  <div*/}
        {/*      dangerouslySetInnerHTML={{*/}
        {/*        __html: event.homemessage*/}
        {/*      }}*/}
        {/*  />*/}
        {/*</Text>*/}
      </EventNameContainer>
      <WaveContainer></WaveContainer>
      <Text important fontWeight={600}>
          Forgot Your Password?
      </Text>
      <Form onSubmit={onSubmit}>
        <InputsContainer>
          <Label htmlFor="email">Email Address</Label>
          <Input
            type="text"
            id="email"
            placeholder="Enter your email here"
            name="email"
            value={email}
            disabled
          ></Input>
          <Button style={{ zIndex: 1 }} type="submit" height="35px" width="280px">
              Login Via Email
          </Button>
        </InputsContainer>
        <LinkContainer>
            <Text style={{ zIndex: 1 }} important fontSize="15px" padding="14px" >
              <Link style={{ textDecoration: 'none' }} to={"/" + eventcode + "/login"}>Return to Login</Link>
            </Text>
          </LinkContainer>
      </Form>
      <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
        <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
          AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
          <span style={{ color: "red" }}>
                <a><Link style={{ textDecoration: 'none' }} to={"/" + eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
              </span>
        </div>
      </div>
    </PageBodyContainer>
  )
}

export default ResetPassword
