import React from "react"
import {Route, Switch} from "react-router"
import SponsorImages from "./SponsorImages"
import {useSelector} from "react-redux";
import {IonFooter, IonToolbar} from "@ionic/react";
import {Link} from "react-router-dom";

const Footer: React.FC = () => {
  const eventData = useSelector((state: any) => state.event)
  return (
      <>
          {(eventData && eventData?.sponsorurl && eventData?.sponsorurl?.length > 0) &&
              <Switch>
                  <Route exact={true} path={"/" + eventData.eventcode + "/welcome"}>
                      <IonFooter color={'primary'} class="ion-no-border">
                          <IonToolbar color={'primary'}>
                              <SponsorImages></SponsorImages>
                          </IonToolbar>
                      </IonFooter>
                  </Route>
                  {/*<Route exact={false} path={"/" + eventData.eventcode + "/auction-items"}>*/}
                  {/*    <IonFooter color={'primary'} class="ion-no-border">*/}
                  {/*        <IonToolbar color={'primary'}>*/}
                  {/*            <SponsorImages></SponsorImages>*/}
                  {/*        </IonToolbar>*/}
                  {/*    </IonFooter>*/}
                  {/*</Route>*/}
              </Switch>
          }
      </>
  )
}

export default Footer
