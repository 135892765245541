/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

/* Theme variables */
import "./theme/variables.css"

import jwt_decode from "jwt-decode"
import React, {useEffect, useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import {Link, Route} from "react-router-dom"
import { BrowserRouter } from "react-router-dom"

import Footer from "./components/Footer/Index"
import Header from "./components/Header/Index"
import AccountContact from "./pages/AccountContact/Index"
import AccountNotifications from "./pages/AccountNotifications/Index"
import AccountProfile from "./pages/AccountProfile/Index"
import AuctionItemsPage from "./pages/AuctionItems/Index"
import Checkout from "./pages/Checkout/Index"
import CreateAccountPage from "./pages/CreateAccount/Index"
import EventPage from "./pages/Event/Index"
import ItemDetailsPage from "./pages/ItemDetails/Index"
import CartPage from "./pages/CartPage/Index"
import LoginPage from "./pages/Login/Index"
import MyAccount from "./pages/MyAccount/Index"
import MyOrders from "./pages/MyOrders/Index"
import NotFound from "./pages/NotFound/NotFound"
import OpenBids from "./pages/OpenBids/Index"
import PlaceBid from "./pages/PlaceBid/Index"
import AutoBid from "./pages/AutoBid/Index";
import PrivacyPolicy from "./pages/PrivacyPolicy/Index"
import ResetPassword from "./pages/ResetPassword/Index"
import ResetPasswordVerification from "./pages/ResetPasswordVerification/Index"
import ResetPasswordAccount from "./pages/ResetPasswordAccount/Index"
import ResetPasswordEmail from "./pages/ResetPasswordEmail/Index"
import SetupAccount from "./pages/SetupAccount/Index"
import VerificationPage from "./pages/Verification/Index"
import VerifyAccount from "./pages/VerifyAccount/Index"
import WelcomePage from "./pages/Welcome/Index"
import {
  fetchAuctionItemsData,
  updateAuctionItems
} from "./redux/slices/auctionItemsSlice"
import {fetchEventData, setAuctionCode, setEventCode} from "./redux/slices/eventSlice"
import {fetchPrivacyPolicy, setPrivacyAuctionCode, setPrivacyEventCode} from "./redux/slices/privacyPolicySlice"
import {checkTokenOnRefresh, updateUserInfo} from "./redux/slices/userSlice"
import {resetNotification, showNotification} from "./redux/slices/notificationSlice";
import {
    AUCTION_ITEMS_ENDPOINT,
    PRIVACY_POLICY,
    USER_PROFILE,
    getEndPoint, CART_ENDPOINT
} from "./util/api/enpoints"
import { getURL } from "../src/constants"
import {ReactNotifications, Store as notificationStore} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import {
    IonContent,
    IonToast,
    IonApp,
} from "@ionic/react";
import { setupIonicReact } from '@ionic/react';
import store from "./redux/store";
import SlideshowPage from "./pages/Slideshow/Index";
import {Redirect, useLocation, useParams} from "react-router";
import useResetEvent from "./hooks/useResetEvent";
import Store from "./pages/Store/Index";
import Purchaser from "./pages/Purchaser/Index";


import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import StripeCheckout from "./pages/StripeCheckout/Index";
import GuestRegistration from "./pages/GuestRegistration/Index";


const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);

const App: React.FC = (props: any) => {
    useResetEvent()
    useEffect(() => {
        window.addEventListener("offline", processOfflineNotification, false)
        window.addEventListener("online", processOnlineNotification, false)

        return () => {
            window.removeEventListener("offline", processOfflineNotification)
            window.removeEventListener("online", processOnlineNotification)
        }
    }, [])

    const processOfflineNotification = () => {
        notificationStore.addNotification({
            title:  "We're having trouble connecting to the internet",
            // message: parsedData.message,
            type: "danger",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            width: 400,
            touchSlidingExit: {
                swipe: {
                    duration: 400,
                    timingFunction: 'ease-out',
                    delay: 0,
                },
                fade: {
                    duration: 400,
                    timingFunction: 'ease-out',
                    delay: 0
                }
            },
            dismiss: {
                duration: 0,
                showIcon: true,
                click: true,
                touch: true
            }
        });
    }

    const processOnlineNotification = () => {
        notificationStore.removeAllNotifications()
    }


    const dispatch = useDispatch()
    const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn)
    const eventcode = useSelector((state: any) => state.user.eventcode)
    const auctioncode = useSelector((state: any) => state.user.auctioncode)
    const notification = useSelector((state: any) => state.notification)
    // const eventcode = useSelector((state: any) => state.event.eventcode)


    const auctionItemsFetchState = useSelector(
    (state: any) => state.auctionItems.status
    )

    let token = localStorage.getItem("token")

  useEffect(() => {

    const validateToken = async () => {
      console.log('validate token')
      /**
       * add the end point to the server side for the same
       * dispacth login actions accordingly
       */

      // console.log(token)

      if (token && token !== "undefined") {
        // CAN I USE A PATTERN MATCH HERE????
        // const regex = /\/events\/[0-9a-zA-Z*]/g;
        // const found = window.location.pathname.match(regex);
        // console.log(Router.map)
        const decoded: any = jwt_decode(token)
        var current_time = Date.now() / 1000;
        console.log(current_time)
        console.log(decoded.exp)
        if (decoded.exp < current_time) {
          // expired
          console.log('expired token')
          localStorage.removeItem("token")
        }
        else {
            const response: any = await dispatch(
                checkTokenOnRefresh({ url: getEndPoint(USER_PROFILE) })
            )
            console.log(response)
            if (response.error) {
                localStorage.removeItem("token")
                console.log(response.error)
            } else if (response.payload) {
                if (decoded.isguest === '1') {
                    dispatch(
                        updateUserInfo({
                            accountstatus: 'guest',
                            firstname: 'Guest',
                            authorizebidder: false,
                            isLoggedIn: true
                        })
                    )
                }
                console.log(decoded)
                if (decoded.eventcode) {
                    // Test to make sure we are the same event else logout
                    if (!window.location.pathname.includes(decoded.eventcode)) {
                        localStorage.removeItem("token")
                    }
                    else {
                        const url = getEndPoint('EVENT_ENDPOINT')
                        const eventData = {
                            url,
                            eventcode: decoded.eventcode,
                            auctioncode: decoded.auctioncode
                        }
                        dispatch(fetchEventData(eventData))
                        dispatch(setEventCode(decoded.eventcode))
                        dispatch(setAuctionCode(decoded.auctioncode))
                        dispatch(setPrivacyEventCode(decoded.eventcode))
                        dispatch(setPrivacyAuctionCode(decoded.auctioncode))
                        // Get auction items due to the "My Orders" needing this data. So if auction items are not
                        // displayed before clicking on "My Orders"
                        if (auctionItemsFetchState === "idle") {
                            dispatch(
                                fetchAuctionItemsData(getEndPoint(AUCTION_ITEMS_ENDPOINT))
                            )
                        }
                        if (auctionItemsFetchState === "succeeded") {
                            dispatch(updateAuctionItems())
                        }

                        dispatch({
                            type: "TOKEN_CONNECT",
                            payload: {
                                url: getURL(),
                                token: token
                            }
                        })
                    }
                }

            }
        }
        }
    }

    validateToken()
  }, [])

    setupIonicReact({
        mode: 'ios'
    });

    useEffect(() => {
        if (auctioncode && eventcode) {
            dispatch(fetchPrivacyPolicy({url: getEndPoint(PRIVACY_POLICY), eventcode: eventcode, auctioncode: auctioncode}))
        }

    }, [auctioncode, eventcode])

  return (
      <BrowserRouter >
          <IonApp >
              <div className="app-container">
                  <ReactNotifications />
              </div>

              <IonToast
                    isOpen={notification.isOpen}
                    onDidDismiss={() => store.dispatch( resetNotification() )}
                    message={notification.message}
                    duration={notification.timeout}
                    buttons={notification.buttons}
                    cssClass={'custom-toast'}
              />
              <Header></Header>
              <IonContent>
                  {/*<Route exact={true} path="/:eventcode/home" component={Home} />*/}
                  <Route exact={true} path="/:eventcode/event/:id" component={Store} />
                  <Route exact={true} path="/:eventcode/event" component={Store} />
                  <Route exact={true} path="/:eventcode/event/purchaserregistration/:id" component={Purchaser} />
                  <Route exact={true} path="/:eventcode/event/guestregistration/:id" component={GuestRegistration} />
                  <Route exact={true} path="/:eventcode/not-found" component={NotFound}></Route>
                  <Route exact={true} path="/:eventcode/verify-email" component={VerificationPage}></Route>
                  <Route exact={true} path="/:eventcode/contact-us" component={AccountContact}></Route>
                  <Route exact={true} path="/:eventcode/verify-email/:id" component={VerificationPage}></Route>
                  <Route exact={true} path="/:eventcode/verify/:id" component={VerifyAccount}></Route>
                  <Route exact={true} strict path="/:eventcode/auth/passwordlessLogin/:loginguid" component={EventPage}></Route>
                  <Route exact={true} strict path="/:eventcode/login" component={LoginPage}></Route>
                  <Route exact={true} strict path="/:eventcode/login/" component={LoginPage}></Route>
                  <Route exact={true} path="/:eventcode/create-account" component={CreateAccountPage}></Route>
                  <Route exact={true} path="/:eventcode/setup-account" component={SetupAccount}></Route>
                  <Route exact={true} path="/:eventcode/reset-password" component={ResetPassword}></Route>
                  <Route exact={true} path="/:eventcode/auth/resetPassword/:id" component={ResetPasswordVerification}></Route>
                  <Route exact={true} path="/:eventcode/reset-password-account" component={ResetPasswordAccount} />
                  <Route exact={true} path="/:eventcode/account-notifications" component={AccountNotifications}></Route>
                  <Route exact={true} path="/:eventcode/account-profile" component={AccountProfile}></Route>
                  <Route exact={true} path="/:eventcode/privacy-policy" component={PrivacyPolicy}></Route>
                  <Route exact={true} path="/:eventcode/reset-password-email" component={ResetPasswordEmail} />
                  <Route exact={true} path="/:eventcode/welcome" component={WelcomePage}></Route>
                  <Route exact={true} path="/:eventcode/auction-items" component={AuctionItemsPage}/>
                  <Route exact={true} path="/:eventcode/auction-items/:id" component={ItemDetailsPage}/>
                  <Route exact={true} path="/:eventcode/place-bid/:id" component={PlaceBid}/>
                  <Route exact={true} path="/:eventcode/auto-bid/:id" component={AutoBid}/>
                  <Route exact={true} path="/:eventcode/my-orders" component={MyOrders}></Route>
                  <Route exact={true} path="/:eventcode/my-account" component={MyAccount}></Route>
                  <Route forceRefresh={true} exact={true} path="/:eventcode/cart" component={CartPage}></Route>
                  <Route exact={true} path="/:eventcode/open-bids" component={OpenBids} />
                  <Route exact={true} path="/:eventcode/stripe" component={StripeCheckout} />
                  <Route exact={true} path="/:eventcode/checkout" component={Checkout} />
                  <Route exact={true} path="/:eventcode/checkout/:orders" component={Checkout} />
                  <Route exact={true} path="/:eventcode/checkout-item/:historyid" component={Checkout}></Route>

                  <Route exact={true} path={["/:eventcode"]} component={EventPage}></Route>
                  <Route exact={true} path={["/:eventcode/detail"]} component={SlideshowPage}></Route>
                  <Route exact={true} path={["/:eventcode/detail/:location"]} component={SlideshowPage}></Route>
                  <Route exact={true} path={["/:eventcode/detail/:location/:timeframe"]} component={SlideshowPage}></Route>
                  <Route exact={true} path={["/:eventcode/list"]} component={SlideshowPage}></Route>
                  <Route exact={true} path={["/:eventcode/list/:location"]} component={SlideshowPage}></Route>
                  <Route exact={true} path={["/:eventcode/list/:location/:timeframe/:pagecount"]} component={SlideshowPage}></Route>
                  <Route exact={true} path={["/:eventcode/list/pc/:pagecount"]} component={SlideshowPage}></Route>

                  {/*<Route exact={true} path="/" >*/}
                  {/*    /!*<Redirect to="https://donorsnap.com" />*!/*/}
                  {/*</Route>*/}
                  <Route exact={true} path="/" component={NotFound}></Route>


                  {/*<Route exact path="/:eventcode" component={EventPage}></Route>*/}
                  {/*<Route exact={true} path="/" component={HomeRedirect}></Route>*/}

                  {/*<div style={{ marginBottom: "20px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 10px", textAlign: "center", fontSize: 14 }}>AuctionSnap All Rights Reserved by DonorSnap Review AuctionSnap{" "}*/}
                  {/*    <span style={{ color: "red" }}>*/}
                  {/*        <a><Link style={{ textDecoration: 'none' }} to="/event/privacy-policy">Privacy Policy</Link></a>*/}
                  {/*    </span>*/}
                  {/*</div>*/}
              </IonContent>
              <Footer></Footer>
          </IonApp>
      </BrowserRouter>
  )
}
export default App
