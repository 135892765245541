import React, { PropsWithChildren } from "react";
import {useHistory, useParams} from "react-router";

import styled from "@emotion/styled";

import waveBluePng from "../../assets/img/svg/wave-blue.png";
import Text from "../../pages/CommonStyles/Text";
import IconBack from "../Icons/IconBack";
import Img from "./Img";
import LogoContainer from "./LogoContainer";
import LogoWrapper from "./LogoWrapper";

type Props = {
  eventResponse: any;
  isEventLoading: boolean;
  showWelcomeMsg: boolean;
  userInfo?: any;
  title?: string;
  path?: string;
};

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 40px;
`;
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`;

const BackContainer = styled.div`
  height: ${(props) => props.theme.components.navBarHeight};
  display: flex;
  align-items: center;
`;

interface Params {
  eventcode: string
}

const AccountProfileHeader: React.FC<PropsWithChildren<Props>> = (
  props: PropsWithChildren<Props>
) => {
  const history = useHistory();
  const { eventcode } = useParams<Params>()
  const goBack = () => {
    const mypath = props.path ? props.path : '/' + eventcode + '/my-account';
    history.push(mypath);
  };

  const { eventResponse, isEventLoading, children } = props;
  return (
    <>
      {!isEventLoading && (
        <>
          <BackContainer onClick={goBack}>
            <IconBack></IconBack>
            <Text important>Back</Text>
          </BackContainer>
          <LogoWrapper>
            <LogoContainer>
              <Img
                alt="logo"
                src={eventResponse.logourl}
              />
            </LogoContainer>
          </LogoWrapper>
            {/*<>*/}
            {/*  <EventNameContainer>*/}
            {/*    <Text*/}
            {/*        fontSize="35px"*/}
            {/*        fontWeight="bold"*/}
            {/*        textAlign="center"*/}
            {/*        padding="24px 0 16px 0">*/}
            {/*      {props.title}*/}
            {/*    </Text>*/}
            {/*  </EventNameContainer>*/}
            {/*  <WaveContainer></WaveContainer>*/}
            {/*</>*/}
        </>
      )}
    </>
  );
};

export default AccountProfileHeader;
