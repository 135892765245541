import styled from "@emotion/styled"
import React from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { updateStateOnLogout } from "../../redux/slices/userSlice"
import {useParams} from "react-router";
import {disconnected} from "../../redux/slices/signalrSlice";

type LinkProps = {
  route: string
  routeName: string
  logout?: boolean
}

const Button = styled.button`
  background: none;
  color: #666666;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  width: 100%;
  text-align: left;
`

interface Params {
  eventcode: string
}


const StyledLink: React.FC<LinkProps> = ({
  route,
  routeName,
  logout = false
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { eventcode } = useParams<Params>()
  const logUserOut = () => {
    dispatch(updateStateOnLogout())
    const token = localStorage.getItem("token")
    localStorage.removeItem("token")
    dispatch(disconnected())
    dispatch({
      type: "DISCONNECT_SIGNALR"
    })

    history.push(route)
  }

  return (
    <>
      {!logout && (
        <Link
          to={route}
          style={{
            color: "inherit",
            textDecoration: "none",
            width: "100%"
          }}
        >
          {routeName}
        </Link>
      )}
      {logout && <Button onClick={logUserOut}>Logout</Button>}
    </>
  )
}

export default StyledLink
