import React, { useEffect } from "react"
import {useDispatch, useSelector} from "react-redux"
import { Link, useHistory } from "react-router-dom"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text"
import {updateStateOnLogout} from "../../redux/slices/userSlice";
import styled from "@emotion/styled";
import waveBluePng from "../../assets/img/svg/wave-blue.png";

const LinkContainer = styled.div`
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
  `

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const VerificationPage: React.FC = () => {
    const elementScroll = document.getElementById("scrollBody")
    if (elementScroll) {
        elementScroll.scroll(0,0)
    }
    
    const eventcode = useSelector((state: any) => state.event.eventcode)
    const email = useSelector((state: any) => state.user.email)
    const event = useSelector((state: any) => state.event)
    const userAccountStatus = useSelector(
    (state: any) => state.user.accountstatus
    )
    const userAccountSetupComplete = useSelector(
    (state: any) => state.user.accountSetupComplete
    )

    const history = useHistory()
    const dispatch = useDispatch()
    const logUserOutClick = () => {
        dispatch(updateStateOnLogout())
        localStorage.removeItem("token")
        dispatch({
            type: "DISCONNECT_SIGNALR"
        })
    }
    const logUserOut = () => {
        return '/' + eventcode
    }

    useEffect(() => {
        if (userAccountStatus === "verified") {
              if (userAccountSetupComplete) {
                /** we'll go to welcom/auction page */
              } else {
                history.push("/" + eventcode + '/setup-account')
              }
        }
    }, [userAccountStatus, userAccountSetupComplete])

  return (
      <PageBodyContainer>
          <EventNameContainer>
              {event.eventname && (
                  <Text
                      fontSize="35px"
                      fontWeight="bold"
                      textAlign="center"
                      padding="24px 0 16px 0"
                  >
                      {event.eventname}
                  </Text>
              )}

              {/*<Text fontSize="20px" textAlign="center" padding="24px">*/}
              {/*    <div*/}
              {/*        dangerouslySetInnerHTML={{*/}
              {/*            __html: event.welcomemessage*/}
              {/*        }}*/}
              {/*    />*/}
              {/*</Text>*/}
          </EventNameContainer>
          <WaveContainer></WaveContainer>
      <Text important padding="14px" fontWeight={600} textAlign="center">
          We just emailed {email}.
      </Text>
      <Text important padding="24px" fontSize="16px" fontWeight={600} textAlign="center">
         Click the "Verify Email Address" button in the email to continue.
      </Text>

        <LinkContainer>
            <Text style={{ zIndex: 1 }} important fontSize="15px" padding="14px" decoration="underline" >
                <Link style={{ textDecoration: 'none' }} onClick={logUserOutClick} to={logUserOut} >Typo in your email address? Return to Login?</Link>
            </Text>
        </LinkContainer>
        <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
          <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
              AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
              <span style={{ color: "red" }}>
                <a><Link style={{ textDecoration: 'none' }} to={"/" + eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
              </span>
          </div>
        </div>
    </PageBodyContainer>
  )
}

export default VerificationPage
