import React, {useEffect, useRef, useState} from "react"
import {useHistory, useParams} from "react-router"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Moment from "react-moment"
// import moment from "moment"

import 'moment-timezone';
import moment from 'moment';
// moment().tz.guess()

import MainButtonItem from "../CommonStyles/MainButtonItem"

import Text from "../CommonStyles/Text"
import { useDispatch, useSelector } from "react-redux"
import FlexRow from "../CommonStyles/FlexRow"
import FlexCol from "../CommonStyles/FlexCol"
import { theme } from "../CommonStyles/Theme"
import styled from "@emotion/styled"
import { mq } from "../../constants"
import {fetchCartData} from "../../redux/slices/cartSlice";
import {CART_ENDPOINT, getEndPoint} from "../../util/api/enpoints";
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import { useAuthRefresh } from "../../hooks/authenicationHook"
import {IonRefresher, IonRefresherContent, IonContent, IonRow, IonGrid, IonCol} from "@ionic/react";
import {Link} from "react-router-dom";
import { DateTime } from "luxon";
import {fetchEventData, setAuctionCode, setEventCode} from "../../redux/slices/eventSlice";
import {setPrivacyEventCode} from "../../redux/slices/privacyPolicySlice";


const FlexColWelcome = styled(FlexCol)`
  ${mq[2]} {
    margin: 0 10px;
  }
`

const FlexRowWelcome = styled(FlexRow)`
  ${mq[0]} {
    justify-content: space-evenly;
  }
  ${mq[3]} {
    justify-content: center;
    overflow-y: auto;
    padding: 10px;
  }
`

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  //height: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

interface RefresherEventDetail {
  complete(): void;
}

const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
  setTimeout(() => {
    // Any calls to load data go here
    // window.location.reload()

    event.detail.complete();
  }, 1000);
}

interface Params {
  eventcode: string
}



const WelcomePage: React.FC = () => {
  const eventState = useSelector((state: any) => state.event)
  const { eventcode } = useParams<Params>()
  useAuthRefresh();

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const elementScroll = document.getElementById("scrollBody")
    if (elementScroll) {
      elementScroll.scroll(0,0)
    }
    dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
  }, [])

  const goToBids = () => {
    history.push("/" + eventcode + "/Cart")
  }

  const goToItems = () => {
    history.push("/" + eventcode + "/auction-items")
  }
  const eventData = useSelector((state: any) => state.event)
  const userInfo = useSelector((state: any) => state.user)
  const [days, setDays] = useState<string | undefined>(undefined)
  const [hours, setHours] = useState<string | undefined>(undefined)
  const [mins, setMins] = useState<string | undefined>(undefined)
  const [seconds, setSeconds] = useState<string | undefined>(undefined)
  const [sponsorImage, setSponsorImage] = useState<string | undefined>(
    undefined
  )

  // eventopendatecst
  // eventcloseddatecst
  // eventbiddingopencst
  // eventbiddingclosedcst


  var local = DateTime.local()
  const centralBiddingOpen = DateTime.fromISO(eventData.eventbiddingopencst, {zone: 'America/Chicago'});
  const localDateBiddingOpen = centralBiddingOpen.setZone(local.zoneName)
  const localDateBiddingOpenString = localDateBiddingOpen.toString()
  const centralBiddingClosed = DateTime.fromISO(eventData.eventbiddingclosedcst, {zone: 'America/Chicago'});
  const localDateBiddingClosed = centralBiddingClosed.setZone(local.zoneName)
  const localDateBiddingClosedString = localDateBiddingClosed.toString()

  // console.log(local.zoneName)
  // console.log(eventData.eventbiddingclosedcst)
  // console.log(localDateBiddingClosed.toString())

  const eventFlag = eventData.eventflag
  const sponsorImages = eventData.sponsorurl
  const cartState = useSelector((state: any) => state.cart)

  useEffect(() => {
    if (local >= localDateBiddingOpen) {
      const url = getEndPoint('EVENT_ENDPOINT')
      const eventBodyData = {
        url,
        eventcode: eventData.eventcode,
        auctioncode: eventData.auctioncode
      }
      dispatch(fetchEventData(eventBodyData))
      dispatch(setEventCode(eventData.eventcode))
      dispatch(setAuctionCode(eventData.auctioncode))
      dispatch(setPrivacyEventCode(eventData.eventcode))
    }



  }, [])

  useEffect(() => {
    if (cartState.status === "idle") {
      dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
    }
  }, [cartState.status])

  useEffect(() => {
    // Init to first image
    let imageIndex = 0
    setSponsorImage(sponsorImages[imageIndex])
    const imageInterval = setInterval(() => {
      if (imageIndex < sponsorImages.length - 1) {
        imageIndex += 1
      } else {
        imageIndex = 0
      }
      setSponsorImage(sponsorImages[imageIndex])
    }, 5000)
    return () => clearInterval(imageInterval)
  }, [sponsorImages])

  useEffect(() => {
    const interval = setInterval(() => {
      const then: any = moment(localDateBiddingClosedString)
      const now: any = moment()
      // tslint:disable-next-line

      var seconds = then.diff(now, 'seconds')
      var days        = Math.floor(seconds/24/60/60);
      var hoursLeft   = Math.floor((seconds) - (days*86400));
      var hours       = Math.floor(hoursLeft/3600);
      var minutesLeft = Math.floor((hoursLeft) - (hours*3600));
      var minutes     = Math.floor(minutesLeft/60);
      var remainingSeconds = seconds % 60;
      function pad(n) {
        return (n < 10 ? "0" + n : n);
      }
      // console.log(pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(remainingSeconds))

      setHours(pad(hours))
      setDays(pad(days))
      setMins(pad(minutes))
      setSeconds(pad(remainingSeconds))
    }, 1000)
    return () => clearInterval(interval)
  }, [localDateBiddingClosedString, days, hours, mins, seconds])

  return (
<>
  {/*style={{--keyboard-offset: 0 !important;}}*/}
  {/*<IonContent scrollY id="scrollBody" >*/}

    {/*<PageBodyContainer style={{  backgroundColor: '#f2f2f2'}}>*/}
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent refreshingSpinner={'circular'} refreshingText={'Loading...'}></IonRefresherContent>
      </IonRefresher>
        <EventNameContainer>
              {eventData.eventname && (
                  <Text
                      fontSize="35px"
                      fontWeight="bold"
                      textAlign="center"
                      padding="24px 0 16px 0"
                  >
                    {eventData.eventname}
                  </Text>
              )}
              {userInfo && (
                  <Text fontSize="20px" textAlign="center">
                    Welcome, {userInfo.firstname} {userInfo.lastname}
                  </Text>
              )}
              {userInfo && (
                  <Text fontSize="16px" textAlign="center" padding="4px">
                    {userInfo.bidderdisplaynumber}
                  </Text>
              )}
        </EventNameContainer>
        <WaveContainer></WaveContainer>
      <IonGrid>
      {eventFlag === "eventclosed" && (
          <IonRow>
            <IonCol >
              <Text
                fontSize="16px"
                padding="20px"
                important
                fontWeight="bold"
                textAlign="center"
              >
                {"THE EVENT IS NOW CLOSED."}
              </Text>
            </IonCol>
          </IonRow>
      )}
      {eventFlag === "biddingclosed" && (
          <IonRow>
            <IonCol >
              <Text
                  fontSize="16px"
                  padding="2px"
                  important
                  fontWeight="bold"
                  textAlign="center"
              >
                {"BIDDING IS NOW CLOSED."}
              </Text>
            </IonCol>
          </IonRow>
      )}
      {eventFlag === "biddingclosed" && (
          <IonRow>
            <IonCol >
              <Text
                  fontSize="16px"
                  padding="6px"
                  important
                  fontWeight="bold"
                  textAlign="center"
              >
                {"PLEASE PROCEED TO CHECKOUT."}
              </Text>
            </IonCol>
          </IonRow>
      )}
      </IonGrid>

      {eventFlag === "eventnotopen" || eventFlag === "biddingnotopen" && (
        <FlexRowWelcome flexWrap={"nowrap"} justifyContent="space-evenly">
          <FlexColWelcome
            padding="5px"
            backgroundColor={theme.colors.white}
            borderRadius="10px"
            filter={`drop-shadow(0px 3px 6px ${theme.colors.dropshadow})`}
            alignItems="center"
          >
            <Text important fontSize="18px" fontWeight={800}>
              BIDDING OPENS
            </Text>
            <Text fontSize="21px" fontWeight={300}>
              <Moment format="ddd, MMM Do">{localDateBiddingOpenString}</Moment>
            </Text>
            <Text fontSize="25px" fontWeight={300}>
              <Moment format="h:mm A">{localDateBiddingOpenString}</Moment>
            </Text>
          </FlexColWelcome>
          <FlexColWelcome
            padding="5px"
            backgroundColor={theme.colors.white}
            borderRadius="10px"
            filter={`drop-shadow(0px 3px 6px ${theme.colors.dropshadow})`}
            alignItems="center"
          >
            <Text important fontSize="18px" fontWeight={800}>
              BIDDING CLOSES
            </Text>
            <Text fontSize="21px" fontWeight={300}>
              <Moment format="ddd, MMM Do">{localDateBiddingClosedString}</Moment>
            </Text>
            <Text fontSize="25px" fontWeight={300}>
              <Moment format="h:mm A">{localDateBiddingClosedString}</Moment>
            </Text>
          </FlexColWelcome>
        </FlexRowWelcome>
      )}
      {eventFlag === "biddingopen" && (
        <FlexRowWelcome flexWrap={"nowrap"} justifyContent="space-evenly">
          <FlexColWelcome
            padding="5px"
            backgroundColor={theme.colors.white}
            borderRadius="10px"
            filter={`drop-shadow(0px 3px 6px ${theme.colors.dropshadow})`}
            alignItems="center"
          >
            <Text important fontSize="18px">
              BIDDING CLOSES
            </Text>
            <Text fontSize="14px" fontWeight={300}>
              <Moment format="ddd, MMM Do">{localDateBiddingClosedString}</Moment>
            </Text>
            <Text fontSize="16px" fontWeight={300}>
              <Moment format="h:mm A">{localDateBiddingClosedString}</Moment>
            </Text>
          </FlexColWelcome>
          {(Number(days) > 0) &&
            <FlexColWelcome
                padding="5px"
                backgroundColor={theme.colors.white}
                borderRadius="10px"
                filter={`drop-shadow(0px 3px 6px ${theme.colors.dropshadow})`}
            >
              <Text textAlign="center">{days}</Text>
              <Text textAlign="center">days</Text>
            </FlexColWelcome>
          }
          {(Number(hours) > 0) &&
            <FlexColWelcome
                padding="5px"
                backgroundColor={theme.colors.white}
                borderRadius="10px"
                filter={`drop-shadow(0px 3px 6px ${theme.colors.dropshadow})`}
            >
              <Text textAlign="center">{hours}</Text>
              <Text textAlign="center">hours</Text>
            </FlexColWelcome>
          }
          {(Number(mins) > 0) &&
            <FlexColWelcome
                padding="5px"
                backgroundColor={theme.colors.white}
                borderRadius="10px"
                filter={`drop-shadow(0px 3px 6px ${theme.colors.dropshadow})`}
            >
              <Text textAlign="center">{mins}</Text>
              <Text textAlign="center">mins</Text>
            </FlexColWelcome>
          }
          {(Number(days) < 1) &&
          <FlexColWelcome
              padding="5px"
              backgroundColor={theme.colors.white}
              borderRadius="10px"
              filter={`drop-shadow(0px 3px 6px ${theme.colors.dropshadow})`}
          >
            <Text textAlign="center">{seconds}</Text>
            <Text textAlign="center">seconds</Text>
          </FlexColWelcome>
          }
        </FlexRowWelcome>
      )}

      {(eventFlag === "biddingclosed" && (cartState.auctionswon && cartState.auctionswon.length > 0))  && (
          <FlexRowWelcome flexWrap={"nowrap"} justifyContent="space-evenly">
            <FlexColWelcome width="100%">
             <MainButtonItem height="45px" onClick={goToBids}>
               Go to My Cart
             </MainButtonItem>
            </FlexColWelcome>
          </FlexRowWelcome>
      )}
      {eventFlag === "eventnotopen" && (

       <MainButtonItem height="45px" onClick={goToItems}>
         View Auction Items
        </MainButtonItem>

      )}
      {eventFlag === "biddingopen" && (
       <FlexRowWelcome flexWrap={"nowrap"} justifyContent="space-evenly">
         {(cartState.auctionswon && cartState.auctionswon.length > 0) && (
             <FlexColWelcome width="100%">
               <MainButtonItem height="45px" backgroundColor="white" color="#D2222D" onClick={goToBids}>
                 Go to My Cart
               </MainButtonItem>
             </FlexColWelcome>
         )}
         <FlexColWelcome width="100%">
                <MainButtonItem height="45px" onClick={goToItems}>
                  View Auction Items
                </MainButtonItem>
          </FlexColWelcome>
       </FlexRowWelcome>
      )}
      <Text fontSize="16px" color="#666666" textAlign="center" padding="20px">
        <div key="mobile_home_message" dangerouslySetInnerHTML={{ __html: eventData.welcomemessage}} />
      </Text>


      <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
        <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
          AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
          <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventState.eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
                  </span>
        </div>
      </div>
    {/*</PageBodyContainer>*/}

  {/*</IonContent>*/}
</>
  )
}

export default WelcomePage
