import './styles.css'

import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import styled from '@emotion/styled'
import {
  IonBackdrop,
  IonCol,
  IonGrid,
  IonModal,
  IonRow,
  IonToast,
  IonToggle
} from '@ionic/react'

import IconMinus from '../../components/Icons/IconMinus'
import IconPlus from '../../components/Icons/IconPlus'
import { useCounter } from '../../hooks/useCounter'
import ButtonItem from '../CommonStyles/ButtonItem'
import Text from '../CommonStyles/Text'
import {resetStatus} from "../../redux/slices/cartSlice";
import {resetOrderStatus} from "../../redux/slices/ordersSlice";
import {useAuthRefresh} from "../../hooks/authenicationHook";
import {fetchAuctionItemDetailsData, resetItemDetailsState} from "../../redux/slices/auctionItemDetailsSlice";
import {AUCTION_ITEM_DETAIL_ENDPOINT, BID_HISTORY, getEndPoint} from "../../util/api/enpoints";
import {fetchBidHistoryData} from "../../redux/slices/bidHistorySlice";
import {useParams} from "react-router";
import {submitMaxBid} from "../../util/api/submitMaxBid";
import {updateAutoBid, updateItemMaxBid} from "../../redux/slices/auctionItemsSlice";
import store from "../../redux/store";
import {current} from "@reduxjs/toolkit";

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`

const BidContainer = styled.div`
  height: 106px;
  background-color: #ffffff;
  width: 100%;
  filter: drop-shadow(0px 2px 5px #00000024);
  box-sizing: border-box;
  border-radius: 10px;
`

const ModalContent = styled.div`
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  height: 32em;
  text-align: left;
  h1 {
    font-family: var(--ion-font-family);
    font-size: 24px/33px;
    font-weight: bold;
  }
  p {
    font-family: var(--ion-font-family);
  }
  letter-spacing: 0px;
  color: #333333;
`

const NumberInput = styled.input`
  height: 100%;
  width: 100%;
  background: none;
  font-size: 36px; 
  font-weight: 800;
  text-align: center;
  border: none;
`

type ButtonContainerProps = {
  applyFilter: boolean
  opacity: number
}

const ButtonContainer = styled.div<ButtonContainerProps>`
  height: 36px;
  width: 36px;
  background-color: #d2222d;
  border-radius: 10px;
  filter: ${(props) =>
    props.applyFilter ? 'drop-shadow(0px 2px 5px #00000080)' : 'none'};
  opacity: ${(props) => props.opacity};
`

type Params = {
  eventcode: string;
  id: string;
};

const AutoBid: React.FC<any> = ({ history }) => {
  const { eventcode } = useParams<Params>()
  useAuthRefresh()
  const params = useParams<Params>()
  const dispatch = useDispatch()
  const itemDetails = useSelector((state: any) => state.auctionItemDetails)
  const [desiredBidIncrement, setDesiredBidIncrement] = useCounter(1)
  const userId = useSelector((state: any) => state.user.userid)

  useEffect(() => {
    /**
     * why do we need this if condition commenting out for now
     */
    // if (parseInt(auctionItemDetailsState.itemid) !== parseInt(params.id)) {}
    dispatch(
        resetItemDetailsState()
    )
    dispatch(
        fetchAuctionItemDetailsData({
          url: getEndPoint(AUCTION_ITEM_DETAIL_ENDPOINT),
          itemId: params.id,
        })
    );
    dispatch(
        fetchBidHistoryData({
          url: getEndPoint(BID_HISTORY),
          itemId: params.id,
        })
    );
  }, [params.id]);

  const auctionItem = useSelector((state: any) =>
      state.auctionItems.auctionitems.find(
          (item: any) => item.itemid === parseInt(itemDetails.itemid)
      )
  )

  useEffect(() => {
    if (!maximumBid) {
      setCurrentLocalBid(itemDetails?.currentBid + itemDetails?.minimumbidincrease)
      setMaximumBid(itemDetails?.currentBid + itemDetails?.minimumbidincrease)
    }

    if (itemDetails?.accountautobidincrement < itemDetails?.minimumbidincrease && desiredBidIncrement < itemDetails?.minimumbidincrease) {
      setDesiredBidIncrement(itemDetails?.minimumbidincrease)
    }
    else {
      setDesiredBidIncrement(itemDetails?.accountautobidincrement)
    }

    if (!desiredBidIncrement) {
      // setDesiredBidIncrement(itemDetails?.accountautobidincrement)
      setMaximumBid(itemDetails?.accountautobidamount)
    }
  }, [auctionItem, itemDetails])

  useEffect(() => {
    /**
     * why do we need this if condition commenting out for now
     */
    // if (parseInt(auctionItemDetailsState.itemid) !== parseInt(params.id)) {}
    dispatch(
        resetItemDetailsState()
    )
    dispatch(
        fetchAuctionItemDetailsData({
          url: getEndPoint(AUCTION_ITEM_DETAIL_ENDPOINT),
          itemId: params.id,
        })
    );
    dispatch(
        fetchBidHistoryData({
          url: getEndPoint(BID_HISTORY),
          itemId: params.id,
        })
    );
  }, []);

  const biddingPrice = (
    lastBid: number,
    startingBid: number,
    numberOfBids: number
  ) => {
    var firstBidPrice = lastBid
    if (numberOfBids === 0 && startingBid > 0) {
      firstBidPrice = startingBid
    } else {
      firstBidPrice = auctionItem?.accountautobidamount
    }

    return firstBidPrice
  }

  const [currentLocalBid, setCurrentLocalBid] = useCounter(
    biddingPrice(
      itemDetails.currentbid,
      auctionItem?.startingbid,
      auctionItem?.numberofbids
    )
  )
  const [bidAutomatically, setBidAutomatically] = useState(false)
  const [maximumBid, setMaximumBid] = useCounter(
    biddingPrice(
      itemDetails.currentbid,
      auctionItem?.startingbid,
      auctionItem?.numberofbids
    )
  )


  const [modalState, setModalState] = useState({
    display: false,
    header: '',
    message: '',
    goBack: false
  })

  const [toastState, setToastState] = useState({
    display: false,
    message: '',
    goBack: false
  })

  const minimumBidToMake = auctionItem?.accountautobidamount

  const increaseMaximumBid = () => {
    setMaximumBid(maximumBid + itemDetails?.minimumbidincrease)
  }

  const decreaseMaximumBid = () => {
    const newMaxBid = maximumBid - itemDetails.minimumbidincrease
    if (
      newMaxBid <= itemDetails.currentbid
    ) {
      setMaximumBid(itemDetails.currentbid)
    } else {
      setMaximumBid(newMaxBid)
    }
  }

  const onMaximumBidChange = (e) => {
    console.log('onmaxbidCHange')
    const { value } = e.target
    if (value === "") {
      return setMaximumBid('')
    }
    setMaximumBid(Math.round(Number(value) * 100) / 100)
  }

  const increaseDesiredBidIncrement = () => {
    setDesiredBidIncrement(desiredBidIncrement + itemDetails.minimumbidincrease)
  }

  const decreaseDesiredBidIncrement = () => {
    const newDesiredBid = desiredBidIncrement - itemDetails.minimumbidincrease
    // if (newDesiredBid <= auctionItem?.accountautobidincrement ?? itemDetails.minimumbidincrease) {
    if (newDesiredBid < itemDetails.minimumbidincrease) {
      setDesiredBidIncrement(itemDetails.minimumbidincrease)
    } else {
      setDesiredBidIncrement(newDesiredBid)
    }
  }

  const onDesiredBidIncrementChange = (e) => {
    const { value } = e.target
    if (value === "") {
      return setDesiredBidIncrement('')
    }
    setDesiredBidIncrement(Math.round(Number(value) * 100) / 100)
  }

  const handleSubmitBid = async (e: any) => {
    e.preventDefault()
    if (Number(currentLocalBid) < Number(minimumBidToMake)) {
      return setToastState({ display: true, message: `Please adjust the max bid amount. The minimum bid for this item is $${minimumBidToMake}.`, goBack: false })
    }
    if (bidAutomatically) {
      if (maximumBid < minimumBidToMake) {
        return setToastState({ display: true, message: `Maximum bid price must be at least $${minimumBidToMake}`, goBack: false })
      }
      if (itemDetails?.minimumbidincrease && desiredBidIncrement < auctionItem?.accountautobidincrement) {
        return setToastState({ display: true, message: `Desired bid increment must be at least $${auctionItem?.accountautobidincrement}`, goBack: false })
      }
    }

    const response = await submitMaxBid({
      itemid: itemDetails.itemid,
      maxbid: maximumBid,
      increment: desiredBidIncrement
    })


    const responseJSON = JSON.parse(response.jsonString)
    dispatch(resetStatus)
    dispatch(resetOrderStatus)

    if (responseJSON.message.length === 0) {
      setModalState({
        display: true,
        header: 'Thank you for bidding!',
        message: 'Congratulations! You are the high bidder.',
        goBack: true
      })

      setTimeout(() => {
        history.push(`/${eventcode}/auction-items/${itemDetails.itemid}`)
      }, 3000)
    } else {
      // store.dispatch(
      //     updateItemMaxBid({
      //       itemid: itemDetails.itemid,
      //       accountautobidamount: maximumBid,
      //       accountautobidincrement: desiredBidIncrement
      //     })
      // )

      // store.dispatch(
      //     updateAutoBid({
      //       itemid: itemDetails.itemid,
      //       userid: userId,
      //       accountisautobid: true,
      //       accountautobidamount: maximumBid,
      //       accountautobidincrement: desiredBidIncrement
      //     })
      // )
// console.log('modal state')
      setModalState({ display: true, header: 'Thank you for setting your max bid.', message: responseJSON.message, goBack: false })

      // setToastState({ display: true, message: responseJSON.message, goBack: false })

      setTimeout(() => {
        setModalState({
          display: false,
          header: '',
          message: responseJSON.message,
          goBack: false
        })
        history.push(`/${eventcode}/auction-items/${itemDetails.itemid}`)

      }, 3000)
    }
  }

  return (
    <>
      {itemDetails && (
        <PageContainer>
          <IonModal isOpen={modalState.display}
                    className="custom-message"
                    initialBreakpoint={0.5}>
            <IonBackdrop />
            <ModalContent>
              <h1>{modalState.header}</h1>
              <p>{modalState.message}</p>
            </ModalContent>
          </IonModal>
          <IonToast
            isOpen={toastState.display}
            onDidDismiss={() => setToastState({
              display: false,
              message: '',
              goBack: false
            })}
            message={toastState.message}
            cssClass={'custom-toast'}
            duration={3000}
        />

          <IonGrid>
            <IonRow>
              <IonCol>
                <Text textAlign={"center"}  fontWeight="600" fontSize="18px">
                  {itemDetails.name}
                </Text>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-align-items-center">
                <Text textAlign={"center"} fontWeight="800" fontSize="16px">
                  Current Bid: ${itemDetails.currentbid}
                </Text>
              </IonCol>
            </IonRow>
              <>
                <IonRow>
                  <IonCol>
                    <BidContainer>
                      <IonRow
                        className="ion-align-items-center ion-justify-content-center"
                        style={{ height: '100%' }}
                      >
                        <IonCol size="2" onClick={decreaseMaximumBid}>
                          <IonRow className="ion-justify-content-center">
                            <ButtonContainer
                              applyFilter={
                                maximumBid <= itemDetails.currentbid
                                  ? true
                                  : false
                              }
                              opacity={
                                maximumBid > itemDetails?.currentbid
                                  ? 1
                                  : 0.5
                              }
                            >
                              <IonRow className="ion-align-items-center">
                                <IonCol>
                                  <IconMinus></IconMinus>
                                </IonCol>
                              </IonRow>
                            </ButtonContainer>
                          </IonRow>
                        </IonCol>
                        <IonCol size="8">
                          <IonRow className="ion-justify-content-center">                  
                            <NumberInput
                              type="number" 
                              value={maximumBid} 
                              onChange={onMaximumBidChange} 
                              min={minimumBidToMake}/>
                          </IonRow>
                          <IonRow className="ion-justify-content-center">
                            <Text fontSize="14px" color="#a2a2a2">
                              Your Maximum Bid
                            </Text>
                          </IonRow>
                        </IonCol>
                        <IonCol size="2" onClick={increaseMaximumBid}>
                          <IonRow className="ion-justify-content-center">
                            <ButtonContainer applyFilter={true} opacity={1}>
                              <IconPlus></IconPlus>
                            </ButtonContainer>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </BidContainer>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <BidContainer>
                      <IonRow
                        className="ion-align-items-center ion-justify-content-center"
                        style={{ height: '100%' }}
                      >
                        <IonCol size="2" onClick={decreaseDesiredBidIncrement}>
                          <IonRow className="ion-justify-content-center">
                            <ButtonContainer
                              applyFilter={
                                desiredBidIncrement <= itemDetails.minimumbidincrease ? false : true
                              }
                              opacity={desiredBidIncrement <= itemDetails.minimumbidincrease ? 0.5 : 1}
                            >
                              <IonRow className="ion-align-items-center">
                                <IonCol>
                                  <IconMinus></IconMinus>
                                </IonCol>
                              </IonRow>
                            </ButtonContainer>
                          </IonRow>
                        </IonCol>
                        <IonCol size="8">
                          <IonRow className="ion-justify-content-center">
                          <NumberInput
                              type="number" 
                              value={desiredBidIncrement} 
                              onChange={onDesiredBidIncrementChange} 
                              min={auctionItem?.accountautobidincrement}/>
                          </IonRow>
                          <IonRow className="ion-justify-content-center">
                            <Text fontSize="14px" color="#a2a2a2">
                              Desired Bid Increment
                            </Text>
                          </IonRow>
                        </IonCol>
                        <IonCol size="2" onClick={increaseDesiredBidIncrement}>
                          <IonRow className="ion-justify-content-center">
                            <ButtonContainer applyFilter={true} opacity={1}>
                              <IconPlus></IconPlus>
                            </ButtonContainer>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </BidContainer>
                  </IonCol>
                </IonRow>

                <IonRow style={{ borderBottom: '1px solid #dedede' }}>
                  <IonCol></IonCol>
                </IonRow>
              </>
            <IonRow>
              <IonCol>
                <ButtonItem onClick={handleSubmitBid} height="45px">Update Max Bid</ButtonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </PageContainer>
      )}
    </>
  )
}

export default AutoBid
