import React, {useEffect, useState} from "react"
import {Redirect, Route, useHistory, useParams} from "react-router"
import './index.css';
import styled from "@emotion/styled";
import axios from "axios";
import {getConfig} from "../../AppConfig";
import {IonBackdrop, IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonToast, IonSpinner} from "@ionic/react";
import {createOutline} from "ionicons/icons";
import Text from "../CommonStyles/Text";
import CurrencyInput from "react-currency-input-field";
import PageBodyContainer from "../CommonStyles/PageBodyContainer";
import {Link} from "react-router-dom";

interface Params {
  eventcode: string
  id: string
}

export const Input = styled("input")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`;

const Select = styled("select")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

const Option = styled("option")`
  box-sizing: border-box;
  background-color: #0000000d;
  outline: none;
  border: none;
  ::placeholder {
    font-size: 14px;
    padding-left: 4px;
  }
`
const GuestRegistration: React.FC = () => {
  const history = useHistory()
  const params = useParams<Params>();
  const { apiUrl } = getConfig()
  const eventcode = params.eventcode
  const eventguid = params.id
  const [toastState, setToastState] = useState({display: false, goback: false, message: ''})
  const [loading, setLoading] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const [registrationId, setRegistrationId] = React.useState('')
  const [guestDefaultData, setGuestDefaultData] = React.useState({})
  const [fieldListData, setFieldListData] = React.useState({guestid: null, registrationtitle: '', guestname: '', groupname: '', storeurl: '', guestfieldslist:[], guestlookuplist: [], selectedregistrationid: null})
  const [errorData, setErrorData] = React.useState([])

  useEffect(()=> {
    const getPurchaserData = async () => {
      setSubmitted(false)
      const purchaserUrl = `${apiUrl}/guestregistration/` + eventguid
      const {data} = await axios.get(purchaserUrl)
      const parsedData = JSON.parse(data.jsonString)
      console.log(parsedData)
      localStorage.setItem('tokenstore', parsedData.token)
      setRegistrationId(parsedData?.selectedregistrationid)
      setFieldListData(parsedData)
    }

    getPurchaserData()
  }, [])

  useEffect(() => {
    var newValues = {}
    fieldListData.guestfieldslist.map(field => {
      const fieldValue = "fieldvalue" + field.fieldcontrol.toString()
      newValues[field.fieldid] = field[fieldValue] ? field[fieldValue] : ''
    })
    setGuestDefaultData(newValues)
  }, [fieldListData.guestfieldslist])

  const putStoreGuestData = async (body) => {
    const token = localStorage.getItem('tokenstore')
    const storeguestUrl = `${apiUrl}/storeguest`

    const {data} = await axios.put(storeguestUrl, body,{headers: {
        'Authorization': `Bearer ${token}`
      }})
    const parsedData = JSON.parse(data.jsonString)
    if (parsedData.status === 'expired') {
      localStorage.removeItem("tokenstore")
      history.push(`/${eventcode}/event`);
    }
    else if (parsedData.status === 'success') {
      setSubmitted(true)
      // setTimeout(() => {
      //   window.location.href = fieldListData?.storeurl
      // }, 3000)
    }
  }

  const getFieldOptions = (guestData) => {
    var options = [<></>]

    if (fieldListData && fieldListData?.guestlookuplist && fieldListData?.guestlookuplist.length > 0) {
      const filteredOptions = fieldListData?.guestlookuplist?.filter(guests => guests.lookupfieldid === guestData.fieldid)

      // console.log(filteredOptions)
      if (filteredOptions && filteredOptions.length > 0) {
        options = filteredOptions.map((guestListDataOption, index) => {
          return (
              <Option value={guestListDataOption?.lookupid} key={index}>
                {guestListDataOption?.lookup}
              </Option>
          )
        })
      }
    }

    return options
  }

  const submitData = async () => {
    setLoading(true)
    setErrorData([])

    // Check for errors on required fields
    var fieldData = []
    var errorArray = []
    var error = false
    fieldListData?.guestfieldslist?.map((guestData) => {
      const fieldValueString = "fieldvalue" + guestData.fieldcontrol
      var dataToAppend = {"fieldid":guestData.fieldid}
      if (guestData.fieldrequired && !guestDefaultData[guestData.fieldid]) {
        errorArray.push({fieldid: guestData.fieldid, message: guestData.fieldlabel + ' is required.'})
        error = true
      }
      if (guestDefaultData[guestData.fieldid]) {
        dataToAppend[fieldValueString] = guestDefaultData[guestData.fieldid]
        fieldData.push(dataToAppend)
      }
    })

    if (!error) {
      var body = {"guestfieldslist": fieldData, "guestid": fieldListData?.guestid}
      body['registrationid'] = Number(registrationId)

      await putStoreGuestData(body)
    }
    else {
      setErrorData(errorArray)
    }
    setLoading(false)
  }
  const updateGuestData = (value, guestData) => {
    const dataObj = guestDefaultData
    var newObject = {...dataObj}
    newObject[guestData.fieldid] = value
    setGuestDefaultData(newObject)

    const item = errorData.find(item => item.fieldid === guestData.fieldid)
    if (item && value) {
      const newError = errorData.filter(removeItem => removeItem.fieldid !== guestData.fieldid)
      setErrorData(newError)
    }
    else if (guestData.fieldrequired && !value) {
      const errorDataCopy = errorData
      errorDataCopy.push({fieldid: guestData.fieldid, message: guestData.fieldlabel + ' is required.'})
      setErrorData(errorDataCopy)
    }
  }
  const getErrorDataObject = (fieldid) => {
    return errorData.find(errorControl5 => errorControl5.fieldid === fieldid)
  }

  const onFinish = () => {
    window.location.href = fieldListData?.storeurl
  }

  return (
      <>
        <IonToast
            isOpen={toastState.display}
            onDidDismiss={() => {
              if (toastState.goback) {
                history.goBack()
              }
              setToastState({
                display: false,
                goback: false,
                message: ''
              })
            }}
            cssClass={'custom-toast'}
            message={toastState.message}
            duration={3000}
        />
        <>
          <div id="box" style={{display: "flex", width: '100%'}}>
            {/*<PageBodyContainer justifyContent="center">*/}
            {/*  <IonSpinner color="primary" name="spindoc"></IonSpinner>*/}
            {/*</PageBodyContainer>*/}
            <IonGrid style={{height: "100%"}}>
              <IonRow>
                <IonCol style={{ display: "flex" }} size="12" className="ion-justify-content-center" >
                  <div style={{ display: "flex", fontSize: '26px' }}>{fieldListData?.registrationtitle}</div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{display: "flex", fontSize: '18px'}} size="12" className="ion-justify-content-center">
                  <div>{fieldListData?.groupname}</div>
                </IonCol>
              </IonRow>
              {!submitted && (
                  <>
                    <IonRow>
                      <IonCol style={{paddingLeft: "20px", paddingRight: "15px", paddingBottom: '15px', display: "flex", fontSize: '18px'}} size="12" className="ion-justify-content-left">
                        <div>Welcome, {fieldListData?.guestname}!</div>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol style={{paddingLeft: "20px", paddingRight: "15px", paddingBottom: '15px', display: "flex", fontSize: '18px'}} size="12" className="ion-justify-content-left">
                        <div>Edit Your Guest Registration</div>
                      </IonCol>
                    </IonRow>
                  </>
                )}

              {submitted && (
                  <>
                    <IonRow>
                      <IonCol style={{ display: "flex" }} size="12" className="ion-justify-content-center" >
                        <div  style={{ display: "flex", paddingTop: '20px', fontSize: '26px' }}><a style={{ textDecoration: 'none', color: '#1589c0'}} href={fieldListData?.storeurl}>{'Thank you for updating your registration!'}</a></div>
                      </IonCol>
                    </IonRow>
                  </>
              )}
              {!submitted && (
                  <>
                    {fieldListData?.guestfieldslist?.map((guestData) => {
                      const errorCheckObj = getErrorDataObject(guestData.fieldid)
                      if (guestData.fieldcontrol === 1) {
                        return (
                            <>
                              <IonRow>
                                <IonCol style={{ paddingLeft: "15px", paddingRight: "15px", display: "flex" }} size={"12"} className="ion-justify-content-right" >
                                  <IonRow className="ion-no-padding" style={{width: '100%'}}>
                                    <IonCol size={"12"} style={{width: '100%', display: "flex"}}>
                                      <Text style={{width: '100%'}} padding="8px" fontSize="14px" fontWeight="bold" color="#666666">{guestData.fieldlabel}</Text>
                                    </IonCol>
                                    <IonCol size={"12"} className="ion-justify-content-right" >
                                      <Select
                                          name={guestData.fieldlabel}
                                          id={guestData.fieldid}
                                          style={{width: '100%', display: "flex"}}
                                          required={guestData.fieldrequired}
                                          // value={state}
                                          // onChange={(e) => {
                                          //     setState(e.currentTarget.value)
                                          // }}
                                          // defaultValue={userData.addressInfo.state}
                                      >
                                        <Option value={'Yes'} key={1}>
                                          {'Yes'}
                                        </Option>
                                        <Option value={'No'} key={2}>
                                          {'No'}
                                        </Option>
                                      </Select>
                                    </IonCol>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                              {errorCheckObj &&
                                  <IonRow>
                                    <IonCol no-padding style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={"12"} className="ion-justify-content-left" >
                                      <span id="error">{errorCheckObj.message}</span>
                                    </IonCol>
                                  </IonRow>
                              }
                            </>
                        )
                      }
                      else if (guestData.fieldcontrol === 2) {
                        return (
                            <>
                              <IonRow>
                                <IonCol style={{ paddingLeft: "15px", paddingRight: "15px", display: "flex" }} size={"12"} className="ion-justify-content-right" >
                                  <IonRow className="ion-no-padding" style={{width: '100%'}}>
                                    <IonCol size={"12"} style={{width: '100%', display: "flex"}}>
                                      <Text style={{width: '100%'}} padding="8px" fontSize="14px" fontWeight="bold" color="#666666">{guestData.fieldlabel}</Text>
                                    </IonCol>
                                    <IonCol size={"12"} className="ion-justify-content-right" >
                                      <Select
                                          name={guestData.fieldlabel}
                                          id={guestData.fieldid}
                                          style={{width: '100%', display: "flex"}}
                                          required={guestData.fieldrequired}
                                          value={guestDefaultData[guestData.fieldid]}
                                          onChange={(e) => {updateGuestData(e.target.value, guestData)}}
                                      >
                                        {getFieldOptions(guestData)}
                                      </Select>
                                    </IonCol>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                              {errorCheckObj &&
                                  <IonRow>
                                    <IonCol no-padding style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={"12"} className="ion-justify-content-left" >
                                      <span id="error">{errorCheckObj.message}</span>
                                    </IonCol>
                                  </IonRow>
                              }
                            </>
                        )
                      }
                      else if (guestData.fieldcontrol === 3) {
                        return (
                            <>
                              <IonRow>
                                <IonCol style={{ paddingLeft: "15px", paddingRight: "15px", display: "flex" }} size={"12"} className="ion-justify-content-right" >
                                  <IonRow className="ion-no-padding" style={{width: '100%'}}>
                                    <IonCol size={"12"} style={{width: '100%', display: "flex"}}>
                                      <Text style={{width: '100%'}} padding="8px" fontSize="14px" fontWeight="bold" color="#666666">{guestData.fieldlabel}</Text>
                                    </IonCol>
                                    <IonCol size={"12"} className="ion-justify-content-right" >
                                      <Input type="number"
                                             id={guestData.fieldid}
                                             placeholder={guestData.fieldlabel}
                                             required={guestData.fieldrequired}
                                             readOnly={guestData.fieldreadonly && guestData.overridereadonly}
                                             maxLength={guestData.fieldtextlength}
                                             value={guestDefaultData[guestData.fieldid]}
                                             onChange={(e) => {updateGuestData(e.target.value, guestData)}}
                                             name={guestData.fieldlabel} style={{width: '100%'}}
                                      />
                                    </IonCol>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                              {errorCheckObj &&
                                  <IonRow>
                                    <IonCol no-padding style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={"12"} className="ion-justify-content-left" >
                                      <span id="error">{errorCheckObj.message}</span>
                                    </IonCol>
                                  </IonRow>
                              }
                            </>
                        )
                      }
                      else if (guestData.fieldcontrol === 4) {
                        return (
                            <>
                              <IonRow>
                                <IonCol style={{ paddingLeft: "15px", paddingRight: "15px", display: "flex" }} size={"12"} className="ion-justify-content-right" >
                                  <IonRow className="ion-no-padding" style={{width: '100%'}}>
                                    <IonCol size={"12"} style={{width: '100%', display: "flex"}}>
                                      <Text style={{width: '100%'}} padding="8px" fontSize="14px" fontWeight="bold" color="#666666">{guestData.fieldlabel}</Text>
                                    </IonCol>
                                    <IonCol size={"12"} className="ion-justify-content-right" >
                                      <CurrencyInput
                                          id={guestData.fieldid}
                                          name={guestData.fieldlabel}
                                          style={{  width: '100%', display: 'flex', justifyContent: 'flex-end', boxSizing: 'border-box', height: '60px', borderRadius: '10px',
                                            textAlign: 'right', backgroundColor: "#e0ebf180", outline: 'none', border: "1px solid #66666660", paddingRight: '10px', paddingLeft: '15px'}}
                                          placeholder={guestData.fieldlabel}
                                          prefix="$"
                                          maxLength={guestData.fieldtextlength}
                                          value={String(guestDefaultData[guestData.fieldid])}
                                          decimalsLimit={2}
                                          disabled={guestData.fieldreadonly && guestData.overridereadonly}
                                          onValueChange={(value, name) => updateGuestData(value, guestData)}
                                      />
                                    </IonCol>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                              {errorCheckObj &&
                                  <IonRow>
                                    <IonCol no-padding style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={"12"} className="ion-justify-content-left" >
                                      <span id="error">{errorCheckObj.message}</span>
                                    </IonCol>
                                  </IonRow>
                              }
                            </>
                        )
                      }
                      else if (guestData.fieldcontrol === 5) {
                        return (
                            <>
                              <IonRow>
                                <IonCol style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={"12"} className="ion-justify-content-left" >
                                  <Input type="text"
                                         id={guestData.fieldid}
                                         placeholder={guestData.fieldlabel}
                                         required={guestData.fieldrequired}
                                         minLength={1}
                                         readOnly={guestData.fieldreadonly && guestData.overridereadonly}
                                         max={guestData.fieldtextlength}
                                         value={guestDefaultData[guestData.fieldid]}
                                         onChange={(e) => {updateGuestData(e.target.value, guestData)}}
                                         name={guestData.fieldlabel} style={{width: '100%'}}
                                  />
                                </IonCol>
                              </IonRow>
                              {errorCheckObj &&
                                  <IonRow>
                                    <IonCol no-padding style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={"12"} className="ion-justify-content-left" >
                                      <span id="error">{errorCheckObj.message}</span>
                                    </IonCol>
                                  </IonRow>
                              }
                            </>
                        )
                      }
                    })}
                    <IonRow style={{marginBottom: '20px'}}>
                      <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                        <IonButton disabled={errorData?.length !== 0} onClick={() => { submitData() }} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                          Save
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </>
              )}

            </IonGrid>
          </div>
        </>
      </>
  )
}

export default GuestRegistration
