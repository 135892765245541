import styled from "@emotion/styled"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {useHistory, useParams} from "react-router"
import { getURL } from "../../constants"
import useFormFields from "../../hooks/FormField"
import {checkTokenOnRefresh, sendUserInfo, updateUserInfo} from "../../redux/slices/userSlice"
import {getEndPoint, CREATE_ACCOUNT, USER_PROFILE} from "../../util/api/enpoints"
import Button from "../CommonStyles/Button"
import Form from "../CommonStyles/Form"
import InputsContainer from "../CommonStyles/InputsContainer"
import Label from "../CommonStyles/Label"
import PageBodyContainer from "../CommonStyles/PageBodyContainer"
import Text from "../CommonStyles/Text";
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import {fetchEventData} from "../../redux/slices/eventSlice";
import {Link} from "react-router-dom";

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const emailLabel = styled("label")`
  font-size: 16px;
  color: ${(props) => props.theme.colors.important};
  text-align: left;
  margin: 8px 10px;
  font-weight: ${(props) => 400};
`

const Input = styled("input")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

interface Params {
  eventcode: string
}

const CreateAccountPage: React.FC = () => {
  const { eventcode } = useParams<Params>()
  const dispatch = useDispatch()
  const history = useHistory()
  const event = useSelector((state: any) => state.event)

  const elementScroll = document.getElementById("scrollBody")
  if (elementScroll) {
    elementScroll.scroll(0,0)
  }

  useEffect(() => {
    const url = getEndPoint('EVENT_ENDPOINT')
    const eventData = {
      url,
      eventcode: eventcode,
      auctioncode: event.auctioncode
    }

    dispatch(
        fetchEventData(eventData)
    )
  }, [eventcode])

  const email = useSelector((state: any) => state.user.email)
  if (!email) {
    history.push('/' + eventcode)
  }
  const eventcodeState = useSelector((state: any) => state.event.eventcode)
  const signalrState = useSelector((state: any) => state.signalr)
  const userAccountStatus = useSelector(
    (state: any) => state.user.accountStatus
  )
  const userAccountSetupComplete = useSelector(
    (state: any) => state.user.accountSetupComplete
  )

  const { formFields, createChangeHandler } = useFormFields({
    password: "",
    confirmPassword: ""
  })

  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    if (errors.length) {
      if (formFields.password === formFields.confirmPassword) {
        setErrors([])
      }
    }
  }, [formFields, errors])

  useEffect(() => {
    if (signalrState.connected) {
      history.push("/" + eventcode + "/verify-email")
    }
  }, [signalrState, userAccountStatus, userAccountSetupComplete, history])

  const onSubmit = async (e: any) => {
    e.preventDefault()

    const password: string = formFields.password
    const confrimPassword: string = formFields.confirmPassword
    if (password !== confrimPassword) {
      setErrors(["Passwords do not match"])
      return
    } else {
      setErrors([])
      const url = getEndPoint(CREATE_ACCOUNT)
      const userInfo = {
        url,
        password,
        email,
        eventcode: eventcode,
        auctioncode:event.auctioncode
      }
      try {
        const response: any = await dispatch(sendUserInfo(userInfo))
        if (response.error) {
          throw new Error("Internal server Error")
        } else if (response.payload) {
          const responseJSON = JSON.parse(response.payload.jsonInfo)
          const token = responseJSON.token
          const accountstatus = responseJSON.accountstatus
          const setup = responseJSON.setupcomplete
          const userid = responseJSON.userid
          console.log(responseJSON)
          if (localStorage.getItem("token")) {
            localStorage.removeItem("token")
          }
          localStorage.setItem("token", token)
          dispatch(
            updateUserInfo({
              accountstatus,
              accountSetupComplete: setup,
              userid,
              isLoggedIn: true
            })
          )

          await dispatch(
              checkTokenOnRefresh({ url: getEndPoint(USER_PROFILE) })
          )

          console.log("response from create account", responseJSON)
          dispatch({
            type: "TOKEN_CONNECT",
            payload: {
              url: getURL(),
              token
            }
          })
        }
      } catch (err) {
        console.log(err)
      }
    }

    // }
  }
  return (
    <PageBodyContainer>
      <EventNameContainer>
        {event.eventname && (
            <Text
                fontSize="35px"
                fontWeight="bold"
                textAlign="center"
                padding="24px 0 16px 0"
            >
              {event.eventname}
            </Text>
        )}

        {/*<Text fontSize="20px" textAlign="center" padding="24px">*/}
        {/*    <div*/}
        {/*        dangerouslySetInnerHTML={{*/}
        {/*            __html: event.homemessage*/}
        {/*        }}*/}
        {/*    />*/}
        {/*</Text>*/}
      </EventNameContainer>
      <WaveContainer></WaveContainer>

      <Form onSubmit={onSubmit}>
        <InputsContainer>
          <Text 
              textAlign="left"
              fontSize="20px"
              important={true}
              margin="8px 10px"
          >
            Email Address:
          </Text>
          <Text
              textAlign="left"
              fontSize="18px"
              margin="8px 10px"
          >
            {email}
          </Text>
        </InputsContainer>
        <InputsContainer>
          <Label htmlFor="password">Password</Label>
          <Input
            type="password"
            id="password"
            placeholder="Enter your password"
            name="password"
            value={formFields.password}
            onChange={createChangeHandler("password")}
          ></Input>
        </InputsContainer>
        <InputsContainer>
          <Label htmlFor="password">Confirm Password</Label>
          <Input
            type="password"
            id="verify-password"
            placeholder="Enter your password"
            name="verify-password"
            value={formFields.confirmPassword}
            onChange={createChangeHandler("confirmPassword")}
          ></Input>
        </InputsContainer>
        {errors.length > 0 &&
          errors.map((err) => <div key={err}>Error : {err}</div>)}
        <Button style={{ zIndex: 1 }}  type="submit" height="35px" width="380px">
          Create New Account
        </Button>
      </Form>
      <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
        <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
          AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
          <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
                  </span>
        </div>
      </div>
    </PageBodyContainer>



  )
}

export default CreateAccountPage
