import styled from "@emotion/styled"
import {IonCol, IonGrid, IonRow, IonToggle} from "@ionic/react"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {Link, useHistory} from "react-router-dom"
import Text from "../CommonStyles/Text"
import {getEndPoint, USER_PROFILE} from "../../util/api/enpoints"
import ButtonItem from "../CommonStyles/ButtonItem";
import {updateStateOnLogout, updateUserProfile, useUserInfo} from "../../redux/slices/userSlice";
import jwt_decode from "jwt-decode";
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import {useAuthRefresh} from "../../hooks/authenicationHook";
import {disconnected} from "../../redux/slices/signalrSlice";
import {useParams} from "react-router";
import PageBodyContainer from "../CommonStyles/PageBodyContainer";

const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0 5px;
`
const LinkText = styled.text`
cursor: pointer;
fontSize: 16px; 
padding: 18px; 
color: #4C4C4C
`

interface Params {
  eventcode: string
}

const MyAccount = () => {
  const { eventcode } = useParams<Params>()
  useAuthRefresh();

  const history = useHistory()
  const dispatch = useDispatch()
  const user = useUserInfo()
  const eventState = useSelector((state: any) => state.event)

  const onToggleChange = (name) => (e) => {
    const url = getEndPoint(USER_PROFILE);
    dispatch(updateUserProfile({ ...user, bidanonymously:e?.target?.checked, ...user.notifications, url }));
  };

  const logUserOut = () => {
    const elementScroll = document.getElementById("scrollBody")
    if (elementScroll) {
      elementScroll.scroll(0,0)
    }

    dispatch(updateStateOnLogout())
    const token = localStorage.getItem("token")
    localStorage.removeItem("token")
    dispatch(disconnected())
    dispatch({
      type: "DISCONNECT_SIGNALR"
    })

    history.push('/' + eventcode)
  }

  const onResetPassword = () => {
    history.push('/' + eventcode + '/reset-password-account')
  }

  const onNotifications = () => {
    history.push('/' + eventcode + '/account-notifications')
  }

  const onEditProfile = () => {
    history.push('/' + eventcode + '/account-profile')
  }

  const onContact = () => {
    history.push('/' + eventcode + '/contact-us')
  }

  return (
        <>
          <EventNameContainer>
            <Text
                fontSize="35px"
                fontWeight="bold"
                textAlign="center"
                padding="24px 0 16px 0"
            >
              My Account
            </Text>
          </EventNameContainer>
          <WaveContainer></WaveContainer>
          <IonGrid>
            <IonRow style={{ height: "50px" }} onClick={onEditProfile} className="ion-align-items-center" >
              <IonCol >
                <LinkText  width="100%">
                  Edit My Profile
                </LinkText>
              </IonCol>
            </IonRow>
            <IonRow style={{ height: "50px" }} onClick={onResetPassword} className="ion-align-items-center">
              <IonCol>
                <LinkText>
                  Reset Password
                </LinkText>
              </IonCol>
            </IonRow>
            {/*eventallowanonymousbidding*/}
            {(eventState.eventallowanonymousbidding) && (
            <IonRow style={{ height: "50px" }}  className="ion-align-items-center">
              <IonCol  sizeXs={'6'} sizeSm={'4'} sizeMd={'3'} sizeLg={'3'} sizeXl={'2'}>
                <LinkText>
                  Bid Anonymously
                </LinkText>
              </IonCol>
              <IonCol
                  className="ion-align-items-begin"
                  // style={{border: '1px solid #00000020'}}
                  sizeXs={'6'} sizeSm={'8'} sizeMd={'9'} sizeLg={'9'} sizeXl={'10'}
              >
                <IonToggle
                    checked={user.bidanonymously}
                    onClick={onToggleChange("bidanonymously")}
                ></IonToggle>
              </IonCol>
            </IonRow>
            )}

            <IonRow style={{ height: "50px" }} onClick={onNotifications} className="ion-align-items-center">
              <IonCol >
                <LinkText>
                  Notifications
                </LinkText>
              </IonCol>
            </IonRow>

            <IonRow onClick={onContact} className="ion-align-items-center">
              <IonCol size="10">
                <Text fontSize="16px" padding="18px" color="#4C4C4C" onClick={onContact}>
                  Contact Us
                </Text>
              </IonCol>
            </IonRow>
            {/*<IonRow className="ion-align-items-center">*/}
            {/*  <IonCol>*/}
            {/*    <ButtonItem height="45px" onClick={logUserOut}>Log Out</ButtonItem>*/}
            {/*  </IonCol>*/}
            {/*</IonRow>*/}
          </IonGrid>
    </>
  )
}

export default MyAccount
